import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            "menu": {
                "dashboard": "Dashboard",
                "search": "Search",
                "summary": "Summary",
                "fileManager": "File Manager",
                "comparison": "Comparison",
                "warehouseConfig": "Warehouse",
                "members": "Users and Roles",
                "logout": "Logout"
            },
            "audit": {
                "SIGN_UP": "User signed up",
                "VERIFIED_ACCOUNT": "User verified their account",
                "RESET_PASSWORD": "User reset their password",
                "LOGIN": "User accessed the system",
                "LOGOUT": "User logged out",
                "READ_SALES": "Viewed sales data",
                "READ_COMPARISON": "Viewed comparison data",
                "UPLOAD_SPREADSHEET": "Uploaded a spreadsheet",
                "DENY_DELETE_SPREADSHEET": "Denied spreadsheet deletion",
                "DOWNLOAD_LOCALE_SPREADSHEET": "Downloaded locale spreadsheet",
                "UPLOAD_LOCALE_SPREADSHEET": "Uploaded locale spreadsheet",
                "DENY_DELETE_LOCALE_SPREADSHEET": "Denied locale spreadsheet deletion",
                "DENY_UPLOAD_LOCALE_SPREADSHEET": "Denied locale spreadsheet upload",
                "CREATE_NEW_WAREHOUSE": "Created a new warehouse",
                "DENY_CREATE_NEW_WAREHOUSE": "Denied new warehouse creation",
                "ADMIN_CHANGE_ACCOUNT_STATUS": "Admin changed account status",
                "ADMIN_CHANGE_ROLE": "Admin changed user role",
                "ADMIN_CHANGE_VERIFIED": "Admin changed account verification",
                "ADMIN_REMOVED_MEMBER": "Admin removed a member",
                "DENY_CHANGE_ACCOUNT_STATUS": "Denied account status change",
                "DENY_CHANGE_ROLE": "Denied role change",
                "DENY_CHANGE_VERIFIED": "Denied account verification change",
                "DENY_REMOVED_MEMBER": "Denied member removal",
                "RESET_CACHE": "Reset cache",
                "DELETE_SPREADSHEET": "Deleted a spreadsheet",
                "DELETE_WAREHOUSE": "Deleted a warehouse",
                "READ_MEMBERS": "Viewed members list",
                "REFRESHED_SESSION": "Refreshed session"
            },
            "lastActivities": "Last Activities",
            "salesSummary": "Sales Search",
            "year": "Year",
            "month": "Month",
            "months": "Months",
            "city": "City",
            "view": "View",
            "warehouse": "Warehouse",
            "pharmacy": "Pharmacy",
            "zipcode": "Zipcode",
            "total": "Total",
            "fileManager": "Spreadsheets",
            "selectFiles": "Select Files",
            "selectedFiles": "Selected Files",
            "upload": "Upload",
            "files": "Files",
            "warehouseSales": "Warehouse Sales",
            "pharmacySales": "Pharmacy Sales",
            "productSales": "Product Sales",
            "suggestionsTitle": "Suggestions",
            "noSuggestions": "No suggestions available.",
            "comparison": "Comparison",
            "fromUntil": "From {{from}} Until {{until}}",
            "January": "January",
            "February": "February",
            "March": "March",
            "April": "April",
            "May": "May",
            "June": "June",
            "July": "July",
            "August": "August",
            "September": "September",
            "October": "October",
            "November": "November",
            "December": "December",
            "warehouseConfigs": "Warehouse Configurations",
            "addNewConfig": "Add New Configuration",
            "name": "Name",
            "email": "Email",
            "isAdmin": "Administrator",
            "active": "Active",
            "accountVerified": "Account Verified",
            "qtdSheets": "Quantity of Sheets",
            "productNameColumn": "Product Name Column",
            "pharmacyNameColumn": "Pharmacy Name Column",
            "quantitySoldColumn": "Quantity Sold Column",
            "cityColumn": "City Column",
            "zipCodeColumn": "Zip Code Column",
            "warehouseConfig": "Warehouse Configurations",
            "enterSingleLetter": "Enter a single letter",
            "add": "Add",
            "addSuccess": "Configuration added successfully",
            "addError": "Error adding configuration",
            "selectPeriod": "Select Period",
            "salesByProduct": "Sales by Product",
            "fromMonth": "From Month",
            "toMonth": "To Month",
            "currentConfigs": "Current Configurations",
            "totalSold": "Total Sold",
            "totalSoldInCurrentMonth": "Total sold in the current month",
            "totalSoldLastMonthActive": "Total sales recorded during the selected month.",
            "changeLastMonthDescription": "Difference in sales compared to the previous month.",
            "salesProgress": "Sales Progress",
            "topCitiesRanking": "Top Cities",
            "topProducts": "Top Products",
            "topWarehouses": "Top Warehouses",
            "topPharmacies": "Top Pharmacies",
            "topCitiesDescription": "Sales distribution across cities",
            "topProductsDescription": "Top-selling products this month.",
            "topWarehousesDescription": "Warehouses with the most sales.",
            "topPharmaciesDescription": "Best-performing pharmacies.",
            "currentSales": "Current Sales",
            "confirmation": "Upload File",
            "removeMember": "Remove Member",
            "wantDeleteMember": "Do you want to remove this member?",
            "confirmUpload": "Want to upload files to warehouse {{warehouse}} for the period {{year}}/{{month}}?",
            "confirm": "Confirm",
            "cancel": "Cancel",
            "salesByWarehouse": "Sales by Warehouse",
            "topCities": "Top Cities",
            "analyze": "Analyse",
            "selectAllWarehouses": "All Warehouses",
            "moreThan" : "More than",
            "nameOf": "Name of",
            "quantitySalesBy" : "Quantity of Sales by",
            "filterByNumberOfSales": "Filter by number of sales",
            "filterByName": "Filter by name",
            "all": "All",
            "quantity": "Quantity",
            "sales": "Sales",
            "percentPrevMonth": "Percentage of sales compared to the previous month",
            "quantityPrevMonth": "Quantity of sales compared to the previous month",
            "prevMonthChange": "Change from previous month",
            "gainsAndLosses": "Gains and Losses",
            "statisticsComparison": "Statistics Comparison",
            "compareEachMonthTip": "Compare each month with the previous one",
            "gainAndLossesOver": "Gains and Losses over ",
            "pnl": "P&L",
            "momFull": "Month over Month",
            "sold": "Sold",
            "downloadLocaleSpreadSheet": "Download Locale Spreadsheet",
            "uploadLocaleSpreadSheet": "Upload Locale Spreadsheet",
            "cacheReseted": "Cache reseted successfully",
            "reset": "Reset",
            "cacheResetMessage": "Not seeing the correct count? Try refreshing your data. This won’t change any of your previous or current data — just clears temporary storage to help show the right information.",
            "resetSelectedPeriod": "Reset Selected Period",
            "hardReset": "Reset All",
            "showProductName": "Show Product Name",
            "showQuantity": "Show Quantity",
            "showWarehouseName": "Show Warehouse Name",
            "hideLowQuantity": "Hide Low Quantities",
            "hideIrregularProducts": "Hide Irregular Products",
            "overview": "Overview",
            "selectAll": "Select All",
            "unselectAll": "Unselect All",
            "statistics": "Statistics",
            "usersAndRoles": "Users and Roles",
            "compareMode": "Compare Mode",
            "comparePrevMonth": "Compare with Previous Month",
            "hello": "Hello",
            "change": "Change",
            "presentingMode": "Presenting Mode",
            "itemsToShow": "Items to Show",
            "rankingNumberMain": "Ranking Numbers to Show",
        }
    },
    pt: {
        translation: {
            "menu": {
                "dashboard": "Dashboard",
                "summary": "Resumo",
                "search": "Busca",
                "fileManager": "Planilhas",
                "comparison": "Comparação",
                "warehouseConfig": "Armazenistas",
                "members": "Usuários e Permissões",
                "logout": "Sair"
            },
            "audit": {
                "SIGN_UP": "Usuário registrou-se",
                "VERIFIED_ACCOUNT": "Usuário verificou a conta",
                "RESET_PASSWORD": "Usuário redefiniu a senha",
                "LOGIN": "Usuário acessou o sistema",
                "LOGOUT": "Usuário saiu do sistema",
                "READ_SALES": "Visualizou dados de vendas",
                "READ_COMPARISON": "Visualizou dados de comparação",
                "UPLOAD_SPREADSHEET": "Enviou uma planilha",
                "DENY_DELETE_SPREADSHEET": "Negou exclusão de planilha",
                "DOWNLOAD_LOCALE_SPREADSHEET": "Baixou planilha local",
                "UPLOAD_LOCALE_SPREADSHEET": "Enviou planilha local",
                "DENY_DELETE_LOCALE_SPREADSHEET": "Negou exclusão de planilha local",
                "DENY_UPLOAD_LOCALE_SPREADSHEET": "Negou envio de planilha local",
                "CREATE_NEW_WAREHOUSE": "Criou um novo armazém",
                "DENY_CREATE_NEW_WAREHOUSE": "Negou criação de armazém",
                "ADMIN_CHANGE_ACCOUNT_STATUS": "Administrador alterou status da conta",
                "ADMIN_CHANGE_ROLE": "Administrador alterou função do usuário",
                "ADMIN_CHANGE_VERIFIED": "Administrador alterou verificação da conta",
                "ADMIN_REMOVED_MEMBER": "Administrador removeu um membro",
                "DENY_CHANGE_ACCOUNT_STATUS": "Negou alteração do status da conta",
                "DENY_CHANGE_ROLE": "Negou alteração de função",
                "DENY_CHANGE_VERIFIED": "Negou alteração da verificação da conta",
                "DENY_REMOVED_MEMBER": "Negou remoção de membro",
                "RESET_CACHE": "Cache foi redefinido",
                "DELETE_SPREADSHEET": "Excluiu uma planilha",
                "DELETE_WAREHOUSE": "Excluiu um armazém",
                "READ_MEMBERS": "Visualizou lista de membros",
                "REFRESHED_SESSION": "Sessão atualizada"
            },
            "lastActivities": "Últimas Atividades",
            "salesSummary": "Resumo de Vendas",
            "year": "Ano",
            "month": "Mês",
            "months": "Meses",
            "city": "Cidade",
            "view": "Vista",
            "warehouse": "Armazém",
            "pharmacy": "Farmácia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gerenciador de Arquivos",
            "selectFiles": "Selecionar Arquivos",
            "selectedFiles": "Arquivos Selecionados",
            "upload": "Upload",
            "files": "Arquivos",
            "warehouseSales": "Vendas por Armazém",
            "pharmacySales": "Vendas por Farmácia",
            "productSales": "Vendas por Produto",
            "suggestionsTitle": "Sugestões",
            "noSuggestions": "Nenhuma sugestão disponível.",
            "comparison": "Comparação",
            "fromUntil": "De {{from}} Até {{until}}",
            "January": "Janeiro",
            "February": "Fevereiro",
            "March": "Março",
            "April": "Abril",
            "May": "Maio",
            "June": "Junho",
            "July": "Julho",
            "August": "Agosto",
            "September": "Setembro",
            "October": "Outubro",
            "November": "Novembro",
            "December": "Dezembro",
            "warehouseConfigs": "Configurações de Armazéns",
            "addNewConfig": "Adicionar Nova Configuração",
            "name": "Nome",
            "email": "Email",
            "isAdmin": "Administrador",
            "active": "Ativo",
            "accountVerified": "Conta Verificada",
            "qtdSheets": "Quantidade de Planilhas",
            "productNameColumn": "Coluna do Nome do Produto",
            "pharmacyNameColumn": "Coluna do Nome da Farmácia",
            "quantitySoldColumn": "Coluna de Quantidade Vendida",
            "cityColumn": "Coluna da Cidade",
            "zipCodeColumn": "Coluna do Código Postal",
            "warehouseConfig": "Configurações de Armazéns",
            "enterSingleLetter": "Digite uma única letra",
            "add": "Adicionar",
            "addSuccess": "Configuração adicionada com sucesso",
            "addError": "Erro ao adicionar configuração",
            "selectPeriod": "Selecione o Período",
            "salesByProduct": "Vendas por Produto",
            "fromMonth": "De Mês",
            "toMonth": "Até Mês",
            "currentConfigs": "Configurações Atuais",
            "totalSold": "Total Vendido",
            "totalSoldInCurrentMonth": "Total vendido no mês atual",
            "totalSoldLastMonthActive": "Vendas totais registradas durante o mês selecionado.",
            "changeLastMonthDescription": "Diferença nas vendas em comparação com o mês anterior.",
            "salesProgress": "Progresso de Vendas",
            "topCitiesRanking": "Top Cidades",
            "topProducts": "Top Produtos",
            "topWarehouses": "Top Armazéns",
            "topPharmacies": "Top Farmácias",
            "topCitiesDescription": "Distribuição de vendas por cidades",
            "topProductsDescription": "Produtos mais vendidos neste mês.",
            "topWarehousesDescription": "Armazéns com mais vendas.",
            "topPharmaciesDescription": "Farmácias com melhor desempenho.",
            "currentSales": "Vendas Atuais",
            "confirmation": "Upload de Arquivo",
            "removeMember": "Remover Membro",
            "wantDeleteMember": "Deseja remover este membro?",
            "confirmUpload": "Deseja fazer upload dos arquivos para o armazém {{warehouse}} do período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Vendas por Armazém",
            "topCities": "Principais Cidades",
            "analyze": "Analisar",
            "selectAllWarehouses": "Todos os Armazéns",
            "moreThan" : "Mais que",
            "nameOf": "Nome de",
            "quantitySalesBy" : "Quantidade de Vendas por",
            "filterByNumberOfSales": "Filtrar por quantidade de vendas",
            "filterByName": "Filtrar por nome",
            "all": "Todos",
            "quantity": "Quantidade",
            "sales": "Vendas",
            "percentPrevMonth": "Porcentagem de vendas em relação ao mês anterior",
            "quantityPrevMonth": "Quantidade de vendas em relação ao mês anterior",
            "prevMonthChange": "Variação do mês anterior",
            "gainsAndLosses": "Ganhos e Perdas",
            "statisticsComparison": "Comparação de Estatísticas",
            "compareEachMonthTip": "Compare cada mês com o anterior",
            "gainAndLossesOver": "Ganhos e Perdas sobre ",
            "pnl": "P&L",
            "momFull": "Mês sobre Mês",
            "sold": "Vendido",
            "downloadLocaleSpreadSheet": "Baixar Planilha de Localidades",
            "uploadLocaleSpreadSheet": "Upload Planilha de Localidades",
            "cacheReseted": "Cache resetado com sucesso",
            "reset": "Resetar",
            "cacheResetMessage": "Não está vendo a contagem correta? Tente atualizar seus dados. Isso não alterará nenhum dado anterior ou atual — apenas limpa o armazenamento temporário para ajudar a mostrar as informações corretas.",
            "resetSelectedPeriod": "Resetar Período Selecionado",
            "hardReset": "Resetar Tudo",
            "showProductName": "Mostrar Nome do Produto",
            "showQuantity": "Mostrar Quantidade",
            "showWarehouseName": "Mostrar Nome do Armazém",
            "hideLowQuantity": "Esconder Quantidades Baixas",
            "hideIrregularProducts": "Esconder Produtos Irregulares",
            "overview": "Visão Geral",
            "selectAll": "Selecionar Todos",
            "unselectAll": "Desmarcar Todos",
            "statistics": "Estatísticas",
            "usersAndRoles": "Usuários e Permissões",
            "compareMode": "Modo de Comparação",
            "comparePrevMonth": "Comparar com o Mês Anterior",
            "hello": "Olá",
            "change": "Variação",
            "presentingMode": "Modo de Apresentação",
            "itemsToShow": "Itens a Mostrar",
            "rankingNumberMain": "Números de Ranking a Mostrar",
        }
    },
    es: {
        translation: {
            "menu": {
                "dashboard": "Dashboard",
                "summary": "Resumen",
                "search": "Búsqueda",
                "fileManager": "Archivos",
                "comparison": "Comparación",
                "warehouseConfig": "Almacenes",
                "members": "Usuarios y Permisos",
                "logout": "Salir"
            },
            "audit": {
                "SIGN_UP": "El usuario se registró",
                "VERIFIED_ACCOUNT": "El usuario verificó su cuenta",
                "RESET_PASSWORD": "El usuario restableció su contraseña",
                "LOGIN": "El usuario accedió al sistema",
                "LOGOUT": "El usuario cerró sesión",
                "READ_SALES": "Visualizó datos de ventas",
                "READ_COMPARISON": "Visualizó datos de comparación",
                "UPLOAD_SPREADSHEET": "Subió una hoja de cálculo",
                "DENY_DELETE_SPREADSHEET": "Denegó la eliminación de hoja",
                "DOWNLOAD_LOCALE_SPREADSHEET": "Descargó hoja local",
                "UPLOAD_LOCALE_SPREADSHEET": "Subió hoja local",
                "DENY_DELETE_LOCALE_SPREADSHEET": "Denegó eliminación de hoja local",
                "DENY_UPLOAD_LOCALE_SPREADSHEET": "Denegó subida de hoja local",
                "CREATE_NEW_WAREHOUSE": "Creó un nuevo almacén",
                "DENY_CREATE_NEW_WAREHOUSE": "Denegó la creación de un almacén",
                "ADMIN_CHANGE_ACCOUNT_STATUS": "Administrador cambió el estado de la cuenta",
                "ADMIN_CHANGE_ROLE": "Administrador cambió el rol del usuario",
                "ADMIN_CHANGE_VERIFIED": "Administrador verificó la cuenta",
                "ADMIN_REMOVED_MEMBER": "Administrador eliminó un miembro",
                "DENY_CHANGE_ACCOUNT_STATUS": "Denegó cambio del estado de cuenta",
                "DENY_CHANGE_ROLE": "Denegó cambio de rol",
                "DENY_CHANGE_VERIFIED": "Denegó cambio de verificación",
                "DENY_REMOVED_MEMBER": "Denegó eliminación de miembro",
                "RESET_CACHE": "Cache reiniciado",
                "DELETE_SPREADSHEET": "Eliminó una hoja de cálculo",
                "DELETE_WAREHOUSE": "Eliminó un almacén",
                "READ_MEMBERS": "Visualizó la lista de miembros",
                "REFRESHED_SESSION": "Sesión actualizada"
            },
            "lastActivities": "Últimas Actividades",
            "salesSummary": "Resumen de Ventas",
            "year": "Año",
            "month": "Mes",
            "months": "Meses",
            "city": "Ciudad",
            "view": "Vista",
            "warehouse": "Almacén",
            "pharmacy": "Farmacia",
            "zipcode": "Código Postal",
            "total": "Total",
            "fileManager": "Gestor de Archivos",
            "selectFiles": "Seleccionar Archivos",
            "selectedFiles": "Archivos Seleccionados",
            "upload": "Subir",
            "files": "Archivos",
            "warehouseSales": "Ventas por Almacén",
            "pharmacySales": "Ventas por Farmacia",
            "productSales": "Ventas por Producto",
            "suggestionsTitle": "Sugerencias",
            "noSuggestions": "No hay sugerencias disponibles.",
            "comparison": "Comparison",
            "fromUntil": "De {{from}} Hasta {{until}}",
            "January": "Enero",
            "February": "Febrero",
            "March": "Marzo",
            "April": "Abril",
            "May": "Mayo",
            "June": "Junio",
            "July": "Julio",
            "August": "Agosto",
            "September": "Septiembre",
            "October": "Octubre",
            "November": "Noviembre",
            "December": "Diciembre",
            "warehouseConfigs": "Configuraciones de Almacenes",
            "addNewConfig": "Agregar Nueva Configuración",
            "name": "Nombre",
            "email": "Correo Electrónico",
            "isAdmin": "Administrador",
            "active": "Activo",
            "accountVerified": "Cuenta Verificada",
            "qtdSheets": "Cantidad de Hojas",
            "productNameColumn": "Columna del Nombre del Producto",
            "pharmacyNameColumn": "Columna del Nombre de la Farmacia",
            "quantitySoldColumn": "Columna de Cantidad Vendida",
            "cityColumn": "Columna de la Ciudad",
            "zipCodeColumn": "Columna del Código Postal",
            "warehouseConfig": "Configuraciones de Almacenes",
            "enterSingleLetter": "Ingrese una sola letra",
            "add": "Agregar",
            "addSuccess": "Configuración agregada con éxito",
            "addError": "Error al agregar configuración",
            "selectPeriod": "Seleccione el Período",
            "salesByProduct": "Ventas por Producto",
            "fromMonth": "Desde Mes",
            "toMonth": "Hasta Mes",
            "currentConfigs": "Configuraciones Actuales",
            "totalSold": "Total Vendido",
            "totalSoldInCurrentMonth": "Total vendido en el mes actual",
            "totalSoldLastMonthActive": "Ventas totales registradas durante el mes seleccionado.",
            "changeLastMonthDescription": "Diferencia en las ventas comparada con el mes anterior.",
            "salesProgress": "Progreso de Ventas",
            "topCitiesRanking": "Principales Ciudades",
            "topProducts": "Top Productos",
            "topWarehouses": "Top Almacenes",
            "topPharmacies": "Top Farmacias",
            "topCitiesDescription": "Distribución de ventas por ciudades",
            "topProductsDescription": "Productos más vendidos este mes.",
            "topWarehousesDescription": "Almacenes con más ventas.",
            "topPharmaciesDescription": "Farmacias con mejor rendimiento.",
            "currentSales": "Ventas Actuales",
            "confirmation": "Subir Archivo",
            "removeMember": "Eliminar Miembro",
            "wantDeleteMember": "¿Desea eliminar este miembro?",
            "confirmUpload": "¿Desea subir los archivos al almacén {{warehouse}} para el período {{year}}/{{month}}?",
            "confirm": "Confirmar",
            "cancel": "Cancelar",
            "salesByWarehouse": "Ventas por Almacén",
            "topCities": "Principales Ciudades",
            "analyze": "Analizar",
            "selectAllWarehouses": "Todos los Almacenes",
            "moreThan" : "Más que",
            "nameOf": "Nombre de",
            "quantitySalesBy" : "Cantidad de Ventas por",
            "filterByNumberOfSales": "Filtrar por cantidad de ventas",
            "filterByName": "Filtrar por nombre",
            "all": "Todos",
            "quantity": "Cantidad",
            "sales": "Ventas",
            "percentPrevMonth": "Porcentaje de ventas en relación al mes anterior",
            "quantityPrevMonth": "Cantidad de ventas en relación al mes anterior",
            "prevMonthChange": "Variación del mes anterior",
            "gainsAndLosses": "Ganancias y Pérdidas",
            "statisticsComparison": "Comparación de Estadísticas",
            "compareEachMonthTip": "Compare cada mes con el anterior",
            "gainAndLossesOver": "Ganancias y Pérdidas sobre ",
            "pnl": "P&L",
            "momFull": "Mes sobre Mes",
            "sold": "Vendido",
            "downloadLocaleSpreadSheet": "Descargar Hoja de Localidades",
            "uploadLocaleSpreadSheet": "Subir Hoja de Localidades",
            "cacheReseted": "Cache reiniciado con éxito",
            "reset": "Reiniciar",
            "cacheResetMessage": "¿No ves la cuenta correcta? Intenta actualizar tus datos. Esto no cambiará ningún dato anterior o actual — solo borra el almacenamiento temporal para mostrar la información correcta.",
            "resetSelectedPeriod": "Reiniciar Período Seleccionado",
            "hardReset": "Reiniciar Todo",
            "showProductName": "Mostrar Nombre del Producto",
            "showQuantity": "Mostrar Cantidad",
            "showWarehouseName": "Mostrar Nombre del Almacén",
            "hideLowQuantity": "Ocultar Cantidades Bajas",
            "hideIrregularProducts": "Ocultar Productos Irregulares",
            "overview": "Visión General",
            "selectAll": "Seleccionar Todos",
            "unselectAll": "Desmarcar Todos",
            "statistics": "Estadísticas",
            "usersAndRoles": "Usuarios y Permisos",
            "compareMode": "Modo de Comparación",
            "comparePrevMonth": "Comparar con el Mes Anterior",
            "hello": "Hola",
            "change": "Variación",
            "presentingMode": "Modo de Presentación",
            "itemsToShow": "Elementos a Mostrar",
            "rankingNumberMain": "Números de Ranking a Mostrar"
        }
    }
};

const storedLanguage = localStorage.getItem('i18nextLng') || 'pt';

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: storedLanguage,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;