import React from 'react';
import {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useTheme} from '@mui/material/styles';
import {getProductColor} from "../colors";

const ResponsiveBarChart = ({
                              filteredSalesByProductData,
                              showLabelChartProduct,
                              showQtdChartProduct,
                              salesByProductData
                            }) => {
  const theme = useTheme(); // Access the Material UI theme.

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow flexible chart sizing
    layout: {
      padding: {
        top: 30, // Prevent overlapping labels
        bottom: 30,
      },
    },
    scales: {
      x: {
        grid: {
          color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
        },
        ticks: {
          color: theme.palette.text.primary,
        },
      },
      y: {
        display: false, // Hide the Y-axis
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          color: theme.palette.text.primary, // Adapt legend text color to the theme
        },
      },
      datalabels: {
        display: true,
        clip: false, // Allow labels to extend outside the canvas
        clamp: true, // Ensure labels stay within chart bounds
        color: theme.palette.text.primary,
        textAlign: 'center',
        offset: 5,
        align: 'end', // Align labels with the top of the bars
        anchor: showLabelChartProduct && showQtdChartProduct ? 'start' : 'end',
        rotation: -90, // Rotate labels for compact display
        font: {
          size: salesByProductData.length > 6 ? 10 : 12, // Adjust font size based on dataset length
          style: 'normal',
          weight: 'bold',
        },
        formatter: (value, context) => {
          const productName = context.dataset.label;
          if (showLabelChartProduct && showQtdChartProduct) {
            return `${productName} ${value}`;
          } if (showLabelChartProduct) {
            return productName;
          } if (showQtdChartProduct) {
            return value;
          }
          return '';
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return filteredSalesByProductData[index]?.month || '';
          },
          label: (tooltipItem) => {
            const datasetLabel = tooltipItem.dataset.label;
            const value = tooltipItem.raw;
            return `${datasetLabel}: ${value}`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: filteredSalesByProductData.map((data) => data.month),
    datasets: Object.keys(filteredSalesByProductData[0] || {})
      .filter((key) => key !== 'month')
      .map((key) => ({
        label: key,
        data: filteredSalesByProductData.map((data) => data[key]),
        backgroundColor: getProductColor(key), // Use the getProductColor function here
      })),
  };

  return (
    <div
      style={{
        padding: '20px',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper,
        height: '400px',
      }}
    >
      <Bar plugins={[ChartDataLabels]} options={chartOptions} data={chartData}/>
    </div>
  );
};

export default ResponsiveBarChart;