import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        textAlign: 'center',
        padding: '1rem',
        background: theme.palette.background.default, // Dynamically set the background based on theme
        color: theme.palette.text.primary, // Dynamically set text color
        marginTop: 'auto',
      }}
    >
      <Typography variant="body2">
        © 2024 Sales Summary App. Made with ❤️ by an anonymous person.
      </Typography>
    </Box>
  );
};

export default Footer;