import React, {useEffect, useState} from 'react';
import axios from '../services/api';
import {useTranslation} from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Snackbar,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";

const Members = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const [open, setOpen] = useState(false);

    const [members, setMembers] = useState([]);
    const [memberState, setMemberState] = useState([]);

    const [selectedMemberToDelete, setSelectedMemberToDelete] = useState(null);

    const handleOpenDialog = (email) => {
        setSelectedMemberToDelete(email);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setSelectedMemberToDelete(null)
        setOpen(false);
    };

    const handleCheckboxChange = async (memberIndex, property) => {
        const updatedMembers = [...memberState];
        const member = updatedMembers[memberIndex];
        const email = member.email;

        try {
            if (property === 'role') {
                // Toggle role between ADMIN and USER
                const newRole = member.role === 'ADMIN' ? 'MEMBER' : 'ADMIN';
                await axios.put(`/members/${email}/role`, { role: newRole });
                member.role = newRole; // Update state after successful API call
            } else if (property === 'active') {
                // Toggle active status
                const newActiveStatus = !member.active;
                await axios.put(`/members/${email}/status`, { active: newActiveStatus });
                member.active = newActiveStatus; // Update state after successful API call
            } else if (property === 'verified') {
                // Toggle verified status
                const newVerifiedStatus = !member.verified;
                await axios.put(`/members/${email}/verified`, { verified: newVerifiedStatus });
                member.verified = newVerifiedStatus; // Update state after successful API call
            }
            setMemberState(updatedMembers);
            setAlert({ open: true, message: `${t(property)} updated successfully`, severity: 'success' });
        } catch (error) {
            console.error(`Failed to update ${property} for member: ${email}`, error);
            setAlert({ open: true, message: error.response.data.message || `Failed to update ${property} for member: ${email}`, severity: 'error' });
        }
    };

    const handleDelete = async () => {
        await axios.delete(`/members/${selectedMemberToDelete}`)
          .then(() => setAlert({ open: true, message: `Deleted`, severity: 'success' }))
          .catch((error) => setAlert({ open: true, message: error.response.data.message || `Failed to delete member`, severity: 'error' }))
          .finally(() => {
              setSelectedMemberToDelete(null)
              setOpen(false);
              fetchMembers();
          });
    }

    useEffect(() => {
        fetchMembers();
    }, []);

    useEffect(() => {
        // Synchronize memberState with members whenever members is updated
        setMemberState(members);
    }, [members]);

    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/members');
            setMembers(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
      <Box sx={{ margin: 4 }}>
          <Typography variant="h4" gutterBottom>
              {t('usersAndRoles')}
          </Typography>

          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                <CircularProgress />
            </Box>
          ) : (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('name')}</TableCell>
                            <TableCell>{t('email')}</TableCell>
                            <TableCell>{t('active')}</TableCell>
                            <TableCell>{t('accountVerified')}</TableCell>
                            <TableCell>{t('isAdmin')}</TableCell>
                            <TableCell>{t('removeMember')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {memberState.map((m, index) => (
                          <TableRow key={m.email}>
                              <TableCell>
                                  {m.firstName} {m.lastName}
                              </TableCell>
                              <TableCell>{m.email}</TableCell>
                              <TableCell>
                                  <Switch
                                    checked={m.active}
                                    onChange={() => handleCheckboxChange(index, 'active')}
                                  />
                              </TableCell>
                              <TableCell>
                                  <Switch
                                    checked={m.verified}
                                    onChange={() => handleCheckboxChange(index, 'verified')}
                                  />
                              </TableCell>
                              <TableCell>
                                  <Switch
                                    checked={m.role === 'ADMIN'}
                                    onChange={() => handleCheckboxChange(index, 'role')}
                                  />
                              </TableCell>
                              <TableCell>
                                  <IconButton aria-label="delete" onClick={() => handleOpenDialog(m.email)}>
                                      <DeleteIcon />
                                  </IconButton>
                              </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
          )}
          <Dialog open={open} onClose={handleCloseDialog}>
              <DialogTitle>{t('removeMember')}</DialogTitle>
              <DialogContent>
                  <DialogContentText>
                      {t('wantDeleteMember')}
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                      {t('cancel')}
                  </Button>
                  <Button onClick={handleDelete} color="primary" autoFocus>
                      {t('confirm')}
                  </Button>
              </DialogActions>
          </Dialog>
          <Snackbar
            open={alert.open}
            autoHideDuration={6000}
            onClose={() => setAlert({ ...alert, open: false })}
          >
              <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                  {alert.message}
              </Alert>
          </Snackbar>
      </Box>
    );
};

export default Members;