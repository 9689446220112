import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Typography
} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Bar, Doughnut} from 'react-chartjs-2';
import axios from '../services/api';
import CustomLineChartDash from './CustomLineChartDash';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart, registerables} from 'chart.js';
import {useTranslation} from "react-i18next";
import LastActivities from "./LastActivities";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import {Close, InvertColorsOffOutlined, InvertColorsOutlined} from "@mui/icons-material";
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import moment from "moment/moment";
import StatisticsSection from "./StatisticsSection";
import {productColors} from "../colors";

Chart.register(...registerables, ChartDataLabels);

const Dashboard = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  // States
  const [selectedCity, setSelectedCity] = useState(localStorage.getItem('selectedCity') || 'LISBON');
  const [selectedPeriod, setSelectedPeriod] = useState(() => {
    const now = new Date();
    const prevMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
    const prevYear = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
    return `${prevYear}-${String(prevMonth + 1).padStart(2, '0')}`; // Format as "YYYY-MM"
  });

  const [ranking, setRanking] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [lineChartData, setLineChartData] = useState(null);
  const [diffSold, setDiffSold] = useState(null);
  const [loading, setLoading] = useState(false);

  const isDataFetched = useRef(false);
  const [presentingMode, setPresentingMode] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [itemsToShow, setItemsToShow] = useState(10);
  const [productData, setProductData] = useState([]);
  const [percentageChanges, setPercentageChanges] = useState([]);
  const [isMonthToMonth, setIsMonthToMonth] = useState(false);
  const [productsToShow, setProductsToShow] = useState([{}]);
  const [modalStatisticsOpen, setModalStatisticsOpen] = useState(false);
  const [productColorFull, setProductColorFull] = useState(localStorage.getItem('productColorFull') === 'true');
  const [rankingNumberMain, setRankingNumberMain] = useState(5);

  useEffect(() => {
    const loadWarehouses = async () => {
      await fetchWarehouses();
    };

    if (warehouses.length === 0) {
      loadWarehouses();
    }
  }, []);

  useEffect(() => {
    if (warehouses.length > 0 && !isDataFetched.current) {
      isDataFetched.current = true;
      fetchAllData();
    }
  }, [selectedCity, selectedPeriod, warehouses]);

  useEffect(() => {
    if (productData.length > 0) {
      calculatePercentageChanges(productData);
    }
  }, [productData, isMonthToMonth, productsToShow]);

  const calculatePercentageChanges = (data) => {
    if (data.length === 0) return; // Exit if there's no data

    // Get the list of product names marked as `show: true`
    const visibleProducts = productsToShow
      .filter(product => product.show)
      .map(product => product.name);

    // Filter `data` to only include quantities for the visible products
    const filteredData = data.map(entry => {
      const filteredTotalSold = visibleProducts.reduce((sum, productName) => {
        return sum + (entry.productQuantities[productName] || 0);
      }, 0);

      return {
        month: entry.month,
        totalSold: filteredTotalSold
      };
    });

    // Calculate percentage changes based on filtered data
    const initialTotal = filteredData[0]?.totalSold || 0;
    const changes = filteredData.map((entry, index) => {
      const currentTotal = entry.totalSold || 0;
      let numericalChange, percentageChange;

      if (index === 0) {
        numericalChange = currentTotal;
        percentageChange = 0;
      } else {
        const baseTotal = isMonthToMonth ? filteredData[index - 1]?.totalSold || 0 : initialTotal;
        numericalChange = currentTotal - baseTotal;
        percentageChange = baseTotal ? ((numericalChange / baseTotal) * 100) : 0;
      }

      return {
        month: entry.month,
        totalSold: currentTotal,
        change: numericalChange,
        percentageChange: percentageChange
      };
    });

    setPercentageChanges(changes);
  };



  const fetchWarehouses = async () => {
    if (warehouses.length > 0) {
      // Warehouses already loaded, skip API call
      return;
    }

    try {
      const cachedWarehouses = JSON.parse(localStorage.getItem('warehouses'));
      if (cachedWarehouses && cachedWarehouses.length > 0) {
        // Use cached data
        setWarehouses(cachedWarehouses);
        return;
      }

      // Fetch from API if no cache
      const response = await axios.get('/storage/warehouses');
      setWarehouses(response.data);
      localStorage.setItem('warehouses', JSON.stringify(response.data));
    } catch (error) {
      console.error('Failed to fetch warehouses:', error);
    }
  };

  const fetchAllData = async () => {
    setLoading(true);
    await Promise.all([fetchRanking(), fetchDiff(), fetchComparisonData()]);
    setLoading(false);
    isDataFetched.current = false;
  };

  const fetchRanking = async () => {
    try {
      const [year, month] = selectedPeriod.split('-');
      const warehousesParam = warehouses.join(',');

      const response = await axios.get(
        `/ranking?year=${year}&month=${month}&warehouses=${warehousesParam}&city=${selectedCity}`
      );
      setRanking(response.data);
    } catch (error) {
      console.error('Failed to fetch ranking:', error);
    }
  };

  const fetchDiff = async () => {
    try {
      const [year, month] = selectedPeriod.split('-');
      const warehousesParam = warehouses.join(',');

      const response = await axios.get(
        `/ranking/sold-diff?year=${year}&month=${month}&warehouses=${warehousesParam}&city=${selectedCity}`
      );
      setDiffSold(response.data.quantity);
    } catch (error) {
      console.error('Failed to fetch sold diff:', error);
    }
  };

  const fetchComparisonData = async () => {
    try {
      const [year, month] = selectedPeriod.split('-');
      const warehousesParam = warehouses.join(',');

      const response = await axios.get('/comparison', {
        params: {
          fromYear: '2024',
          fromMonth: '03',
          toYear: year,
          toMonth: month,
          city: selectedCity,
          view: 'product',
          warehouses: warehousesParam,
        },
      });
      const transformedData = transformToChartData(response.data);
      setLineChartData(transformedData);

      const productData = [];
      const salesByProductData = {};
      const salesByWarehouseData = {};

      const responseData = response.data;

      // Iterate over each month and aggregate data
      Object.keys(responseData).forEach(monthKey => {
        const entries = responseData[monthKey];
        const [year, month] = monthKey.split('-');
        const monthFormatted = `${t(moment(month, 'MM').format('MMMM'))} ${year}`;

        // Aggregate total sales for each month
        const totalSold = entries.reduce((sum, item) =>
          sum + Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0), 0);

        productData.push({
          month: monthFormatted,
          totalSold: totalSold,
          productQuantities: entries.reduce((acc, item) => {
            Object.keys(item.productQuantities).forEach(productName => {
              if (!acc[productName]) {
                acc[productName] = 0;
              }
              acc[productName] += item.productQuantities[productName];
            });
            return acc;
          }, {})
        });

        // Process product quantities by month
        entries.forEach(item => {
          Object.keys(item.productQuantities).forEach(productName => {
            if (!salesByProductData[productName]) {
              salesByProductData[productName] = { name: productName };
            }
            if (!salesByProductData[productName][monthFormatted]) {
              salesByProductData[productName][monthFormatted] = 0;
            }
            salesByProductData[productName][monthFormatted] += item.productQuantities[productName];
          });

          // Process total sales by warehouse for each month
          if (!salesByWarehouseData[item.viewName]) {
            salesByWarehouseData[item.viewName] = { name: item.viewName };
          }
          if (!salesByWarehouseData[item.viewName][monthFormatted]) {
            salesByWarehouseData[item.viewName][monthFormatted] = 0;
          }
          salesByWarehouseData[item.viewName][monthFormatted] += Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0);
        });
      });

      // Sort the product data by date for chart display
      productData.sort((a, b) => moment(a.month, 'MMMM YYYY').unix() - moment(b.month, 'MMMM YYYY').unix());
      setProductData(productData);

      setProductsToShow(
        Object.keys(salesByProductData)
          .map((name) => ({
            name,
            show: name === name.toUpperCase() // Set `show: true` only if the name is all uppercase
          }))
          .sort((a, b) => {
            // Sort by uppercase status first, then alphabetically
            const isAUpper = a.name === a.name.toUpperCase();
            const isBUpper = b.name === b.name.toUpperCase();

            if (isAUpper && !isBUpper) return -1; // a comes before b
            if (!isAUpper && isBUpper) return 1;  // b comes before a
            return a.name.localeCompare(b.name);  // Alphabetical order within the same group
          })
      );

      calculatePercentageChanges(productData);
    } catch (error) {
      console.error('Failed to fetch comparison data:', error);
    }
  };

  const handleViewModeChange = (event) => {
    const isMonthToMonthSelected = event.target.value === 'monthToMonth';
    setIsMonthToMonth(isMonthToMonthSelected);
    calculatePercentageChanges(productData);
  };

  const handleProductChange = (e) => {
    const { value, checked } = e.target;

    setProductsToShow((prevProducts) =>
      prevProducts.map((product) => {
        if (product.name === value) {
          return {
            ...product,
            show: checked
          };
        }
        return product;
      })
    );

    calculatePercentageChanges(productData);
  }

  const handleCheckAllProducts = () => {
    setProductsToShow((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        show: true
      }))
    );

    calculatePercentageChanges(productData);
  }

  const handleUncheckAllProducts = () => {
    setProductsToShow((prevProducts) =>
      prevProducts.map((product) => ({
        ...product,
        show: false
      }))
    );

    calculatePercentageChanges(productData);
  }

  const formatChangeColor = (value, theme) => {
    if (value > 0) {
      return theme.palette.success.main; // Dynamic green color for positive values
    }
    if (value < 0) {
      return theme.palette.error.main; // Dynamic red color for negative values
    }
    return theme.palette.text.primary; // Neutral text color for zero
  };

  const handleModalStatisticsOpen = () => {
    setModalStatisticsOpen(true);
  }

  const handleModalStatisticsClose = () => {
    setModalStatisticsOpen(false);
  }

  const transformToChartData = (rawData) => {
    const months = Object.keys(rawData);
    const totalSalesData = months.map((month) =>
      rawData[month].reduce((total, entry) => total + entry.totalSales, 0)
    );
    return {
      labels: months,
      datasets: [
        {
          label: t('totalSold'),
          data: totalSalesData,
          borderColor: theme.palette.primary.main,
          backgroundColor: 'transparent',
          tension: 0.4,
        },
      ],
    };
  };

  const handleCardClick = (title, data) => {
    setModalTitle(title);
    setModalData(data);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    handleModalStatisticsClose();
    setModalOpen(false);
  };

  const handleItemsToShowChange = (event) => {
    setItemsToShow(event.target.value);
  };

  const generatePeriodOptions = () => {
    const now = new Date();
    const options = [];
    for (let year = 2024; year <= now.getFullYear(); year++) {
      for (let month = 0; month < 12; month++) {
        if (year === now.getFullYear() && month >= now.getMonth()) break;
        options.push(`${year}-${String(month + 1).padStart(2, '0')}`);
      }
    }
    return options;
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const handlePresentingMode = () => {
    setPresentingMode(!presentingMode);
  }

  const handleProductColorFull = () => {
    setProductColorFull(!productColorFull);
    localStorage.setItem('productColorFull', !productColorFull);
  }

  const handleRankingNumberMainChange = (event) => {
    setRankingNumberMain(event.target.value);
    //localStorage.setItem('rankingNumberMain', event.target.value);
  }

  const transformData = (data) =>
    Object.entries(data).map(([name, quantity]) => ({
      name,
      quantity,
    }));

  const generateBarChartData = (data) => {
    const sortedData = [...data]
      .sort((a, b) => b.quantity - a.quantity) // Ascending order
      .slice(0, itemsToShow); // Limit to selected number of items
    return {
      labels: sortedData.map((item) => item.name),
      datasets: [
        {
          label: t('quantity'),
          data: sortedData.map((item) => item.quantity),
          backgroundColor: theme.palette.primary.light,
          borderColor: theme.palette.primary.dark,
          borderWidth: 1,
        },
      ],
    };
  };

  const getProductColor = (productName, index) => {
    // Find the color for the given product name
    const productColorEntry = productColors.find(colorObj => Object.keys(colorObj)[0] === productName);

    if (productColorEntry && productColorFull) {
      // Extract color and make it more transparent
      const [r, g, b] = productColorEntry[productName]
        .match(/\d+/g)
        .map(Number); // Extract RGB values
      return `rgba(${r}, ${g}, ${b}, 0.5)`; // Set alpha to 0.5 for transparency
    }

    // Fall back to blue gradient if not found
    const baseBlue = '54, 162, 235'; // RGB value for blue
    const opacity = 0.9 - index * 0.15; // Gradually decrease opacity
    return `rgba(${baseBlue}, ${Math.max(opacity, 0.2)})`; // Ensure minimum opacity
  };

  const generateDoughnutChartData = (data) => {
    // Sort data in descending order by quantity
    const sortedData = [...data].sort((a, b) => b.quantity - a.quantity);

    return {
      labels: sortedData.map((item) => item.name),
      datasets: [
        {
          label: 'Top Items',
          data: sortedData.map((item) => item.quantity),
          backgroundColor: sortedData.map((item, index) => getProductColor(item.name.toUpperCase(), index)),
          borderColor: 'transparent',
          borderWidth: 30, // Subtle spacing between slices
          hoverOffset: 20, // Spacing on hover
        },
      ],
    };
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>

      <Grid
        container
        spacing={3}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: 5 }}
      >
        {/* City Selector */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{t('city')}</InputLabel>
            <Select value={selectedCity} onChange={handleCityChange}>
              <MenuItem value="LISBON">Lisbon</MenuItem>
              <MenuItem value="PORTO">Porto</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {/* Period Selector */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel>{t('period')}</InputLabel>
            <Select value={selectedPeriod} onChange={handlePeriodChange}>
              {generatePeriodOptions().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Ranking Number Main Selector */}
        {false && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <FormControl fullWidth>
              <InputLabel>{t('rankingNumberMain')}</InputLabel>
              <Select value={rankingNumberMain} onChange={handleRankingNumberMainChange}>
                {[5, 10, 15, 20].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {/* Presenting Mode */}
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <FormControlLabel
            control={<Switch checked={presentingMode} onChange={handlePresentingMode} />}
            label={t('presentingMode')}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              ml: 0,
            }}
          />
        </Grid>
      </Grid>

      {loading && (
        <Box style={{marginTop: 10, marginBottom: 10}} display="flex" justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress />
        </Box>
      )}

      <Grid container spacing={3}>
        {/* Cards */}
        {(ranking) && (
          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: 'rgba(25, 118, 210, 0.1)', // Subtle primary background
                border: '1px solid',
                borderColor: 'primary.main',
                boxShadow: 2,
              }}
            >
              <CardContent onClick={() => {
                setIsMonthToMonth(false);
                handleCardClick(t('salesProgress'), null);
                setModalStatisticsOpen(true);
              }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: 'bold', color: 'primary.dark', mb: 1 }}
                >
                  {t('totalSold')}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ fontWeight: 'bold', color: 'primary.main' }}
                >
                  {ranking.totalSold}
                </Typography>
                {!presentingMode && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    {t('totalSoldLastMonthActive')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}

        {(lineChartData) && (
          <Grid item xs={12} sm={6} md={3}>
            <Card
              sx={{
                height: '200px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: diffSold >= 0
                  ? 'rgba(76, 175, 80, 0.1)' // Light green for positive difference
                  : 'rgba(244, 67, 54, 0.1)', // Light red for negative difference
                border: '1px solid',
                borderColor: diffSold >= 0 ? 'success.main' : 'error.main',
                boxShadow: 2,
              }}
            >
              <CardContent onClick={() => {
                setIsMonthToMonth(true);
                handleCardClick(t('salesProgress'), null);
                setModalStatisticsOpen(true);
              }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: diffSold >= 0 ? 'success.dark' : 'error.dark',
                    mb: 1,
                  }}
                >
                  {t('change')}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 'bold',
                    color: diffSold >= 0 ? 'success.main' : 'error.main',
                  }}
                >
                  {diffSold >= 0 ? `+${diffSold}` : diffSold}
                </Typography>
                {!presentingMode && (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 1 }}
                  >
                    {t('changeLastMonthDescription')}
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Grid>
        )}
        {(lineChartData) && (
          <Grid item xs={12} sm={12} md={6}>
            <Card sx={{ height: '200px' }}>
              <CardContent onClick={() => {
                setIsMonthToMonth(false);
                handleCardClick(t('salesProgress'), null);
                setModalStatisticsOpen(true);
              }}>
                <Typography variant="h6">{t('salesProgress')}</Typography>
                <Box sx={{ height: '150px' }}>
                  <CustomLineChartDash chartData={lineChartData} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        )}

        {(ranking) && (
          <>
            {[
              {
                title: t('topProducts'),
                data: transformData(ranking.products),
                description: !presentingMode ? t('topProductsDescription') : '',
                iconZoom: <ZoomInOutlinedIcon sx={{ color: 'primary.main', fontSize: 30 }} />,
                icon: <ShoppingCartIcon sx={{ color: 'primary.main', fontSize: 30 }} />,
                iconColorFull: <InvertColorsOutlined sx={{ color: 'primary.main', fontSize: 30 }} />,
                iconNotColorFull: <InvertColorsOffOutlined sx={{ color: 'primary.main', fontSize: 30 }} />,
              },
              {
                title: t('topCitiesRanking'),
                data: transformData(ranking.city),
                description: !presentingMode ? t('topCitiesDescription') : '',
                iconZoom: <ZoomInOutlinedIcon sx={{ color: 'primary.main', fontSize: 30 }} />,
                icon: <LocationCityIcon sx={{ color: 'primary.main', fontSize: 30 }} />
              },
              {
                title: t('topPharmacies'),
                data: transformData(ranking.pharmacies),
                description: !presentingMode ? t('topPharmaciesDescription') : '',
                iconZoom: <ZoomInOutlinedIcon sx={{ color: 'primary.main', fontSize: 30 }} />,
                icon: <LocalPharmacyIcon sx={{ color: 'primary.main', fontSize: 30 }} />
              },
              {
                title: t('topWarehouses'),
                data: transformData(ranking.warehouses),
                description: !presentingMode ? t('topWarehousesDescription') : '',
                iconZoom: <ZoomInOutlinedIcon sx={{ color: 'primary.main', fontSize: 30 }} />,
                icon: <WarehouseIcon sx={{ color: 'primary.main', fontSize: 30 }} />
              },
            ].map(({
                    title,
                    data,
                    description,
                    iconZoom,
                    iconColorFull,
                    iconNotColorFull,
                    icon }, index) => {

              const totalValue = data
                .slice(0, rankingNumberMain)
                .reduce((acc, item) => acc + item.quantity, 0);

              return (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Card
                    sx={{
                      height: '400px',
                      position: 'relative', // Enables absolute positioning inside
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      backgroundColor: 'background.paper',
                      border: '1px solid',
                      borderColor: 'divider',
                      boxShadow: 2,
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        left: 10,
                        zIndex: 1,
                      }}
                      onClick={() => handleCardClick(title, data)}
                    >
                      {iconZoom}
                    </Box>
                    {(iconColorFull && iconNotColorFull) && (
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: 10,
                          right: 10,
                          zIndex: 1,
                        }}
                        onClick={handleProductColorFull}
                      >
                        {productColorFull ? iconNotColorFull : iconColorFull}
                      </Box>
                    )}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        zIndex: 1,
                      }}
                      onClick={() => handleCardClick(title, data)}
                    >
                      {icon}
                    </Box>
                    <CardContent>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: 'bold', mb: 1, color: 'text.primary' }}
                        onClick={() => handleCardClick(title, data)}
                      >
                        {title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}
                                  onClick={() => handleCardClick(title, data)}
                      >
                        {description}
                      </Typography>

                      <Box
                        sx={{
                          height: '250px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Doughnut
                          data={generateDoughnutChartData(data.slice(0, rankingNumberMain))}
                          options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                              legend: {
                                display: true,
                                position: 'right',
                                labels: {
                                  color: theme.palette.text.primary,
                                  usePointStyle: true,
                                  pointStyle: 'circle',
                                  boxWidth: 10,
                                  padding: 10,
                                },
                              },
                              datalabels: {
                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                font: {
                                  weight: 'bold',
                                  size: 14,
                                },
                                formatter: (value) => value,
                                anchor: 'center',
                                align: 'center',
                              },
                            },
                            cutout: '50%',
                          }}
                        />
                      </Box>

                      <Typography
                        variant="body1"
                        sx={{ textAlign: 'center', mt: 2, fontWeight: 'bold' }}
                        color="text.primary"
                      >
                        Total: {totalValue}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </>
        )}

        {/* Modal */}
        <Dialog
          open={modalOpen}
          onClose={handleModalClose}
          fullWidth
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: 3, // Rounded corners
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
            },
          }}
        >
          <DialogTitle
            sx={{
              fontWeight: 'bold',
              color: theme.palette.text.primary,
              fontSize: '1.5rem',
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            {modalTitle}
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              padding: 3,
            }}
          >
            {modalData && (
              <Box sx={{ mb: 2 }} style={{marginTop: 10}}>
                <FormControl fullWidth>
                  <InputLabel>{t('itemsToShow')}</InputLabel>
                  <Select
                    value={itemsToShow}
                    onChange={handleItemsToShowChange}
                    sx={{
                      fontSize: '1rem',
                    }}
                  >
                    {[5, 10, 15, 20].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {(modalStatisticsOpen && percentageChanges.length > 0) && (
              <Box sx={{ height: '650px', mt: 2 }}>
                {/* Other components */}
                <StatisticsSection
                  t={t}
                  productsToShow={productsToShow}
                  handleViewModeChange={handleViewModeChange}
                  isMonthToMonth={isMonthToMonth}
                  handleProductChange={handleProductChange}
                  handleCheckAllProducts={handleCheckAllProducts}
                  handleUncheckAllProducts={handleUncheckAllProducts}
                  percentageChanges={percentageChanges}
                  formatChangeColor={formatChangeColor}
                  showTable={false}
                />
              </Box>

            )}

            {modalData && (
              <Box
                sx={{
                  height: '400px',
                  mt: 2,
                  p: 2, // Add padding for better spacing
                  backgroundColor: theme.palette.background.paper, // Optional background color
                  borderRadius: 2, // Add rounded corners
                  boxShadow: 3, // Subtle shadow for better visibility
                }}
              >
                <Bar
                  data={generateBarChartData(modalData)}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false, // Keep the legend hidden
                      },
                      datalabels: {
                        display: true,
                        align: 'center', // Center-align values
                        anchor: 'center',
                        color: (context) => {
                          const barColor = context.dataset.backgroundColor;
                          return theme.palette.mode === 'dark'
                            ? '#000' // Dark font for light bars
                            : '#fff'; // Light font for dark bars
                        },
                        font: {
                          size: 12,
                          weight: 'bold',
                        },
                        formatter: (value) => value,
                      },
                    },
                    scales: {
                      x: {
                        ticks: {
                          color: theme.palette.text.secondary,
                          font: {
                            size: 14,
                            weight: '500',
                          },
                        },
                        grid: {
                          drawOnChartArea: false, // Remove vertical grid lines
                          color: theme.palette.divider,
                        },
                      },
                      y: {
                        ticks: {
                          color: theme.palette.text.secondary,
                          font: {
                            size: 12,
                            weight: '500',
                          },
                          stepSize: 10, // Optional: Adjust step size
                        },
                        grid: {
                          drawOnChartArea: true,
                          color: theme.palette.divider,
                          borderDash: [5, 5], // Dashed grid lines for a clean look
                        },
                      },
                    },
                    elements: {
                      bar: {
                        borderRadius: 12, // Smooth rounded edges
                      },
                    },
                  }}
                />
              </Box>
            )}
          </DialogContent>
        </Dialog>

        {(!presentingMode) && (
          <Grid item xs={12}>
            <LastActivities />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Dashboard;