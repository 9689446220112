import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from '@mui/material';

const EnhancedTable = ({
                         view,
                         productNames,
                         handleSort,
                         orderBy,
                         orderDirection,
                         filteredSummary,
                         diff,
                         t,
                         renderCell,
                         totalRow,
                       }) => {
  const theme = useTheme(); // Access the current theme for dynamic styling.

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : '#fff',
        borderRadius: 2, // Rounded corners
        boxShadow: theme.shadows[3], // Subtle shadow for better aesthetics
      }}
    >
      <Table>
        {/* Header */}
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.mode === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
            }}
          >
            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {view === 'warehouse' ? t('warehouse') : t('pharmacy')}
            </TableCell>
            {productNames.map((productName) => (
              <TableCell
                key={productName}
                sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
              >
                <TableSortLabel
                  active={orderBy === productName}
                  direction={orderBy === productName ? orderDirection : 'asc'}
                  onClick={() => handleSort(productName)}
                >
                  {productName}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell
              sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
            >
              <TableSortLabel
                active={orderBy === 'total'}
                direction={orderBy === 'total' ? orderDirection : 'asc'}
                onClick={() => handleSort('total')}
              >
                {t('total')}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>

        {/* Body */}
        <TableBody>
          {filteredSummary.map((item, index) => {
            const diffItem = diff.find((d) => d.viewName === item.viewName) || {};
            return (
              <TableRow key={index}>
                <TableCell>{item.viewName}</TableCell>
                {productNames.map((productName) => (
                  <TableCell key={productName}>
                    {renderCell(
                      item.productQuantities[productName],
                      diffItem.productQuantities?.[productName]
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {renderCell(item.totalSales, diffItem.totalSales)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>

        {/* Footer */}
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: theme.palette.mode === 'dark'
                ? theme.palette.grey[800]
                : theme.palette.grey[200],
            }}
          >
            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {t('total')}
            </TableCell>
            {totalRow.totalProducts.map((total, index) => (
              <TableCell key={index}>
                {renderCell(total, totalRow.totalDiff[index])}
              </TableCell>
            ))}
            <TableCell>
              {renderCell(
                totalRow.totalSales,
                diff.reduce((sum, item) => sum + item.totalSales, 0)
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </TableContainer>
  );
};

export default EnhancedTable;