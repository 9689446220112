import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl, InputLabel, useTheme } from '@mui/material';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const theme = useTheme(); // Access the current theme

  const handleChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage);
    localStorage.setItem('i18nextLng', selectedLanguage);
  };

  return (
    <FormControl
      variant="outlined"
      sx={{
        minWidth: 120,
        marginLeft: 2,
      }}
    >
      <InputLabel
        sx={{
          color: '#fff',
        }}
      >
        Language
      </InputLabel>
      <Select
        value={i18n.language}
        onChange={handleChange}
        label="Language"
        sx={{
          color: '#fff',
          '& .MuiSelect-icon': {
            color: '#fff',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              backgroundColor: theme.palette.background.paper,
              '& .MuiMenuItem-root': {
                color: theme.palette.text.primary, // Menu items text color
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: theme.palette.action.selected, // Background for selected
                color: theme.palette.mode === 'dark' ? '#fff' : '#000', // Ensure selected item color
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: theme.palette.action.hover, // Hover background
              },
            },
          },
        }}
      >
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="pt">Português</MenuItem>
        <MenuItem value="es">Español</MenuItem>
      </Select>
    </FormControl>
  );
};

export default LanguageSelector;