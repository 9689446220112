import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  Grid,
  Button,
  Switch,
} from '@mui/material';
import CustomLineChart from './CustomLineChart';
import CustomBarChart from './CustomBarChart';
import CustomTable from './CustomTable';

const StatisticsSection = ({
                             t,
                             productsToShow,
                             handleViewModeChange,
                             isMonthToMonth,
                             handleProductChange,
                             handleCheckAllProducts,
                             handleUncheckAllProducts,
                             percentageChanges,
                             formatChangeColor,
                             showTable=true
                           }) => {
  return (
    <Box mt={4} p={1}>
      <Typography variant="h5" gutterBottom>
        {t('statistics')}
      </Typography>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="viewMode"
          name="viewMode"
          value={isMonthToMonth ? 'monthToMonth' : 'overview'}
          onChange={handleViewModeChange}
        >
          <FormControlLabel
            value="overview"
            control={<Radio />}
            label={t('overview')}
          />
          <FormControlLabel
            value="monthToMonth"
            control={<Radio />}
            label={t('momFull')}
          />
        </RadioGroup>
      </FormControl>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={0} alignItems="center">
        {productsToShow.map((product) => (
          <Grid
            item
            xs={1}
            sm={1}
            md={1.2}
            key={product.name}
            style={{ padding: '4px' }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={product.show}
                  onChange={handleProductChange}
                  value={product.name}
                  size="small"
                />
              }
              label={<span style={{ fontSize: '12px' }}>{product.name}</span>}
            />
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={2} sm={2} md={2}>
          <Button
            variant="outlined"
            color="primary"
            style={{ fontSize: '11px' }}
            onClick={handleCheckAllProducts}
          >
            {t('selectAll')}
          </Button>
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Button
            variant="outlined"
            color="primary"
            style={{ fontSize: '11px' }}
            onClick={handleUncheckAllProducts}
          >
            {t('unselectAll')}
          </Button>
        </Grid>
      </Grid>
      <div style={{ marginBottom: 10 }}></div>
      {!isMonthToMonth ? (
        <CustomLineChart percentageChanges={percentageChanges} t={t} />
      ) : (
        <CustomBarChart
          percentageChanges={percentageChanges}
          t={t}
          showLegend={false}
        />
      )}
      {showTable && (
        <CustomTable
          percentageChanges={percentageChanges}
          t={t}
          formatChangeColor={formatChangeColor}
        />
      )}
    </Box>
  );
};

export default StatisticsSection;