import React from 'react';
import {Line} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useTheme} from '@mui/material/styles';

const CustomLineChart = ({ percentageChanges, t }) => {
  const theme = useTheme();

  // Calculate min and max dynamically
  const dataValues = percentageChanges.map((data) => data.totalSold);
  const minValue = Math.min(...dataValues);
  const maxValue = Math.max(...dataValues);

  // Add proportional padding to avoid flat visuals
  const rangeBuffer = (maxValue - minValue) * 0.2 || 10; // 20% of the range or a minimum buffer of 10
  const yAxisMin = Math.max(0, Math.floor(minValue - rangeBuffer)); // Prevent negatives
  const yAxisMax = Math.ceil(maxValue + rangeBuffer);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 40,
        bottom: 20,
      },
    },
    scales: {
      y: {
        display: false,
        suggestedMin: yAxisMin, // Set dynamic minimum
        suggestedMax: yAxisMax, // Set dynamic maximum
      },
      x: {
        grid: {
          color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
        },
        ticks: {
          color: theme.palette.text.primary,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
        align: 'top',
        anchor: 'end',
        clip: false,
        clamp: true,
        color: theme.palette.text.primary,
        textAlign: 'center',
        offset: 5,
        font: {
          weight: 'bold',
          size: 12,
          style: 'normal',
        },
        formatter: (value, context) => {
          const index = context.dataIndex;
          const { totalSold, change, percentageChange } = percentageChanges[index] || {};

          if (Number(index) === 0) {
            return `${t('sold')}: ${totalSold}`;
          }
          if (change !== undefined && percentageChange !== undefined) {
            return `${t('sold')}: ${totalSold}\n${change > 0 ? '+' : ''}${change} (${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%)`;
          }
          return '';
        },
      },
    },
    backgroundColor: theme.palette.background.paper, // Chart canvas background
  };

  const chartData = {
    labels: percentageChanges.map(({ month }) => month),
    datasets: [
      {
        label: t('totalSold'),
        data: percentageChanges.map((data) => data.totalSold),
        backgroundColor: theme.palette.mode === 'dark'
          ? 'rgba(54, 162, 235, 0.2)'
          : 'rgba(54, 162, 235, 0.2)',
        borderColor: theme.palette.primary.main,
        fill: true,
        tension: 0.25,
      },
    ],
  };

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.paper, // Div container background for the chart
        padding: '20px',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
        height: '400px',
      }}
    >
      <Line
        plugins={[ChartDataLabels]}
        options={chartOptions}
        data={chartData}
      />
    </div>
  );
};

export default CustomLineChart;