import React, { useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Link, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import axios from './services/api';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ResetPassword from './pages/ResetPassword';
import Search from './components/Search';
import Comparison from './components/Comparison';
import FileManager from './components/FileManager';
import WarehouseConfig from './components/WarehouseConfig';
import Footer from './components/Footer';
import LanguageSelector from './components/LanguageSelector';
import Members from './components/Members';
import Dashboard from './components/Dashboard';
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useThemeContext } from './components/ThemeContext';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';

const drawerWidth = 240;
const collapsedWidth = 70;

const App = () => {
  const { isAuthenticated, logout } = useContext(AuthContext) || {};
  const [isDrawerCollapsed, setDrawerCollapsed] = useState(true);
  const { t } = useTranslation();
  const theme = useTheme();
  const { mode, toggleTheme } = useThemeContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [userEmail, setUserEmail] = useState('');

  const toggleDrawer = () => {
    setDrawerCollapsed((prev) => !prev);
  };

  // Fetch user email from the profile API
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get('/profile/me'); // API call
        setUserEmail(response.data.email);
      } catch (error) {
        console.error('Failed to fetch user profile', error);
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [isAuthenticated]);

  const sideMenuItems = [
    { label: t('menu.dashboard'), path: '/dashboard', icon: <DashboardIcon /> },
    { label: t('menu.search'), path: '/search', icon: <SearchIcon /> },
    { label: t('menu.comparison'), path: '/comparison', icon: <CompareArrowsIcon /> },
    { label: t('menu.fileManager'), path: '/file-manager', icon: <FolderIcon /> },
    { label: t('menu.warehouseConfig'), path: '/warehouse-config', icon: <SettingsIcon /> },
    { label: t('menu.members'), path: '/members', icon: <GroupIcon /> },
  ];

  const sideMenu = (
    <List>
      {sideMenuItems.map(({ label, path, icon }, index) => (
        <ListItem
          button
          key={index}
          component={Link}
          to={path}
          onClick={() => isMobile && setDrawerCollapsed(true)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isDrawerCollapsed && !isMobile ? 'center' : 'flex-start',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: isDrawerCollapsed && !isMobile ? 'auto' : 40,
              justifyContent: 'center',
            }}
          >
            {icon}
          </ListItemIcon>
          {!isDrawerCollapsed && (
            <ListItemText
              primary={label}
              sx={{
                opacity: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            />
          )}
        </ListItem>
      ))}
    </List>
  );

  return (
    <Router>
      <CssBaseline />
      <Box sx={{ display: 'flex', width: '100%' }}>
        {/* AppBar */}
        <AppBar position="fixed" sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <Toolbar>
            {isAuthenticated && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="toggle menu visibility"
                onClick={toggleDrawer}
                sx={{ mr: 2 }}
              >
                {isDrawerCollapsed ? <MenuIcon /> : <MenuOpenIcon />}
              </IconButton>
            )}
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {isMobile ? '' : 'Bioksan Sales Management'}
            </Typography>
            {isAuthenticated && (
              <>
                {!isMobile && (
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Typography
                      variant="h7"
                      sx={{ color: 'inherit', textAlign: 'center', fontWeight: '500' }}
                    >
                      {userEmail ? `${t('hello')}, ${userEmail}` : ''}
                    </Typography>
                  </Box>
                )}
                <IconButton color="inherit" onClick={toggleTheme}>
                  {mode === 'light' ? <LightModeIcon /> : <DarkModeIcon />}
                </IconButton>
                <LanguageSelector />
                <Button color="inherit" onClick={logout}>
                  {t('menu.logout')}
                </Button>
              </>
            )}
          </Toolbar>
        </AppBar>

        {/* Drawer */}
        {isAuthenticated && (
          <Drawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={!isMobile || !isDrawerCollapsed}
            onClose={() => setDrawerCollapsed(true)}
            sx={{
              '& .MuiDrawer-paper': {
                width: isDrawerCollapsed && !isMobile ? collapsedWidth : drawerWidth,
                transition: 'width 0.3s',
                overflowX: 'hidden',
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.text.primary,
              },
            }}
          >
            <Toolbar />
            <Box sx={{ mt: 2 }}>{sideMenu}</Box>
          </Drawer>
        )}

        {/* Main Content */}
        <Box
          sx={{
            display: 'flex',
            width: '100%', // Ensure the layout spans the full width
            height: '100vh', // Ensure the layout fills the viewport height
            overflowX: 'hidden', // Prevent horizontal scrolling
            overflowY: 'auto', // Allow vertical scrolling if necessary
          }}
        >
          {/* Drawer */}
          {isAuthenticated && (
            <Drawer
              variant={isMobile ? 'temporary' : 'permanent'}
              open={!isMobile || !isDrawerCollapsed}
              onClose={() => setDrawerCollapsed(true)}
              sx={{
                '& .MuiDrawer-paper': {
                  width: isDrawerCollapsed && !isMobile ? collapsedWidth : drawerWidth,
                  transition: 'width 0.3s',
                  overflowX: 'hidden',
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
              }}
            >
              <Toolbar />
              <Box sx={{ mt: 2 }}>{sideMenu}</Box>
            </Drawer>
          )}

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              marginLeft: isAuthenticated && !isMobile
                ? isDrawerCollapsed
                  ? `${collapsedWidth}px`
                  : `${drawerWidth}px`
                : 0,
              transition: 'margin-left 0.3s ease',
              overflowX: 'hidden', // Prevent horizontal scrolling in the main content
              width: '100%', // Ensure the content doesn't overflow horizontally
            }}
          >
            <Toolbar />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/search"
                element={
                  <PrivateRoute>
                    <Search />
                  </PrivateRoute>
                }
              />
              <Route
                path="/comparison"
                element={
                  <PrivateRoute>
                    <Comparison />
                  </PrivateRoute>
                }
              />
              <Route
                path="/file-manager"
                element={
                  <PrivateRoute>
                    <FileManager />
                  </PrivateRoute>
                }
              />
              <Route
                path="/warehouse-config"
                element={
                  <PrivateRoute>
                    <WarehouseConfig />
                  </PrivateRoute>
                }
              />
              <Route
                path="/members"
                element={
                  <PrivateRoute>
                    <Members />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to={isAuthenticated ? '/dashboard' : '/login'} />} />
            </Routes>
            <Footer />
          </Box>
        </Box>
      </Box>
    </Router>
  );
};

export default App;