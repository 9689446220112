import { useTheme } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, registerables } from 'chart.js';

// Register Chart.js components and plugins
Chart.register(...registerables, ChartDataLabels);

const CustomLineChartDash = ({ chartData }) => {
  const theme = useTheme(); // Access MUI theme

  const isDarkMode = theme.palette.mode === 'dark';
  const fontColor = isDarkMode ? '#FFFFFF' : '#000000';

  // Extract min and max dynamically
  const dataValues = chartData.datasets.flatMap((dataset) => dataset.data); // Flatten all datasets' data
  const minValue = Math.min(...dataValues);
  const maxValue = Math.max(...dataValues);

  // Add proportional padding to avoid flat visuals
  const rangeBuffer = (maxValue - minValue) * 0.2 || 10; // 20% of the range or a minimum buffer of 10
  const yAxisMin = Math.max(0, Math.floor(minValue - rangeBuffer)); // Prevent negatives
  const yAxisMax = Math.ceil(maxValue + rangeBuffer);

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        backgroundColor: isDarkMode ? 'rgba(30, 30, 30, 0.9)' : 'rgba(255, 255, 255, 0.95)',
        titleColor: fontColor,
        bodyColor: fontColor,
        borderColor: isDarkMode ? '#444444' : '#CCCCCC',
        borderWidth: 1,
        cornerRadius: 8,
        padding: 10,
        displayColors: false, // Hide color indicator boxes
        callbacks: {
          label: (context) => `Value: ${context.formattedValue}`,
        },
      },
      datalabels: {
        align: 'top',
        anchor: 'start',
        color: fontColor,
        font: {
          size: 12,
          weight: 'bold',
        },
        formatter: (value) => Math.round(value),
        offset: -20, // Add spacing between point and label
        clip: false, // Prevent clipping outside the chart
      },
    },
    elements: {
      point: {
        radius: 3,
        hoverRadius: 7,
        borderWidth: 1,
        backgroundColor: (context) => {
          const index = context.dataIndex;
          return index % 2 === 0
            ? isDarkMode
              ? 'rgba(144, 202, 249, 1)' // Light blue for dark mode
              : 'rgba(25, 118, 210, 1)' // Darker blue for light mode
            : isDarkMode
              ? 'rgba(100, 181, 246, 1)'
              : 'rgba(21, 101, 192, 1)';
        },
      },
      line: {
        tension: 0.4, // Smoother curves
        borderWidth: 1.5,
        borderColor: isDarkMode ? '#90CAF9' : '#1976D2', // Dynamic line color
        backgroundColor: 'transparent',
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            size: 12,
            weight: 'bold',
          },
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
        },
      },
      y: {
        display: false,
        min: yAxisMin, // Dynamic minimum value
        max: yAxisMax, // Dynamic maximum value
        ticks: {
          display: true,
          color: fontColor,
          font: {
            size: 12,
          },
          stepSize: Math.ceil((yAxisMax - yAxisMin) / 5), // Dynamic step size with at most 5 ticks
        },
        grid: {
          color: isDarkMode ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.05)',
          borderDash: [5, 5], // Dashed gridlines for improved appearance
          lineWidth: 1,
        },
      },
    },
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default CustomLineChartDash;