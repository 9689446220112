import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@mui/material/styles';

const CustomBarChart = ({ percentageChanges, t, showLegend = true }) => {
  const theme = useTheme(); // Access the Material UI theme.

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow flexible chart sizing
    layout: {
      padding: {
        top: 30, // Prevent overlapping labels
        bottom: 20,
      },
    },
    scales: {
      x: {
        grid: {
          color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : theme.palette.grey[300],
        },
        ticks: {
          color: theme.palette.text.primary,
        },
      },
      y: {
        display: false, // Hide the Y-axis
      },
    },
    plugins: {
      legend: {
        display: showLegend, // Dynamically control legend visibility
        position: 'bottom',
        labels: {
          usePointStyle: true,
          font: {
            size: 11,
          },
          color: theme.palette.text.primary, // Adapt legend text color to the theme
        },
      },
      datalabels: {
        display: true,
        color: theme.palette.text.primary,
        textAlign: 'center',
        align: 'end', // Position at the top of the bar
        anchor: 'end',
        clip: false, // Allow labels outside the bar
        clamp: true, // Ensure labels remain within chart bounds
        offset: 0, // Add spacing between the bar and label
        font: {
          weight: 'bold',
          size: 11,
        },
        formatter: (value, context) => {
          const index = context.dataIndex;
          const { totalSold, change, percentageChange } = percentageChanges[index] || {};

          if (Number(index) === 0) {
            return `${t('sold')}: ${totalSold}`;
          }
          if (change !== undefined && percentageChange !== undefined) {
            return `${t('sold')}: ${totalSold}\n${change > 0 ? '+' : ''}${change} (${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%)`;
          }
          return '';
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems) => {
            const index = tooltipItems[0].dataIndex;
            return percentageChanges[index]?.month || '';
          },
          label: (tooltipItem) => {
            const index = tooltipItem.dataIndex;
            const { totalSold, change, percentageChange } = percentageChanges[index] || {};
            if (change !== undefined && percentageChange !== undefined) {
              return `${t('sold')}: ${totalSold}, ${t('prevMonthChange')}: ${change > 0 ? '+' : ''}${change} (${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%)`;
            }
            return `${t('sold')}: ${totalSold}`;
          },
        },
      },
    },
  };

  const chartData = {
    labels: percentageChanges.map(({ month }) => month),
    datasets: [
      {
        label: t('totalSold'),
        data: percentageChanges.map((data) => data.percentageChange),
        backgroundColor: percentageChanges.map((data) =>
          data.percentageChange > 0
            ? theme.palette.success.light // Positive change color
            : theme.palette.error.light // Negative change color
        ),
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#8884d8',
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.9,
      },
    ],
  };

  return (
    <div
      style={{
        padding: '20px',
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.paper, // Match table and line chart background
        height: '400px', // Same height configuration as the line chart container
      }}
    >
      <Bar
        plugins={[ChartDataLabels]}
        options={chartOptions} // No need to spread additional plugins
        data={chartData}
      />
    </div>
  );
};

export default CustomBarChart;