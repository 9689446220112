import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  useTheme,
} from '@mui/material';

const CustomTable = ({ percentageChanges, t, formatChangeColor }) => {
  const theme = useTheme();

  return (
    <TableContainer
      component={Paper}
      sx={{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 2,
        boxShadow: theme.shadows[3],
        marginTop: 2
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {t('month')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {t('sold')}
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {t('change')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {percentageChanges.map(({ month, totalSold, change, percentageChange }, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography variant="body2" color="textPrimary">
                  {month}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  <strong>{t('sold')}:</strong> {totalSold}
                </Typography>
              </TableCell>
              <TableCell>
                {index > 0 && (
                  <Typography
                    variant="body2"
                    sx={{ color: formatChangeColor(change, theme), fontWeight: 'bold' }}
                  >
                    {change > 0 ? `+${change}` : change} (
                    {percentageChange > 0 ? `+${percentageChange.toFixed(2)}%` : `${percentageChange.toFixed(2)}%`}
                    )
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;