import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const ThemeContext = createContext();

export const ThemeProviderWrapper = ({ children }) => {
  // Load the user's theme preference from localStorage or default to 'light'
  const [mode, setMode] = useState(() => {
    return localStorage.getItem('themeMode') || 'light';
  });

  // Function to toggle theme and save to localStorage
  const toggleTheme = () => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('themeMode', newMode); // Save theme mode
      return newMode;
    });
  };

  // Ensure localStorage is checked on initial render
  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          background: {
            default: mode === 'light' ? '#f9f9f9' : '#2b2b2b', // Softer dark mode
            paper: mode === 'light' ? '#ffffff' : '#424242', // Grey left menu
          },
          primary: {
            main: mode === 'light' ? '#1976d2' : '#90caf9', // Lighter primary in dark mode
          },
          text: {
            primary: mode === 'light' ? '#000000' : '#e0e0e0', // Adjusted text for visibility
          },
        },
      }),
    [mode]
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);