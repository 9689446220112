import React, {useEffect, useState} from 'react';
import axios from '../services/api';
import {useTranslation} from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
    Switch,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import {styled} from '@mui/system';
import moment from 'moment';

import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';
import {colors} from "../colors";
import ResponsiveBarChart from "./ResponsiveBarChart";
import StatisticsSection from "./StatisticsSection";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));

const Comparison = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [fromMonth, setFromMonth] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [city, setCity] = useState(localStorage.getItem('city') || 'Lisbon');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [allWarehousesSelected, setAllWarehousesSelected] = useState(true);
    const [loading, setLoading] = useState(false);
    const [productData, setProductData] = useState([]);
    const [salesByProductData, setSalesByProductData] = useState([]);
    const [salesByWarehouseData, setSalesByWarehouseData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [percentageChanges, setPercentageChanges] = useState([]);
    const [isMonthToMonth, setIsMonthToMonth] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });

    const [showLabelChartProduct, setShowLabelChartProduct] = useState(false);
    const [showQtdChartProduct, setShowQtdChartProduct] = useState(true);
    const [showLabelChartWarehouse, setShowLabelChartWarehouse] = useState(false);
    const [showQtdChartWarehouse, setShowQtdChartWarehouse] = useState(true);

    const [hideIrregularProducts, setHideIrregularProducts] = useState(true);
    const [productsToShow, setProductsToShow] = useState([{}]);
    const [numberOfTopRankings, setNumberOfTopRankings] = useState(10);
    const [view, setView] = useState(localStorage.getItem('view') || 'warehouse');

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await axios.get('/storage/warehouses');
                setWarehouses(response.data);
                setSelectedWarehouses(allWarehousesSelected ? response.data : []);

                // const getFirstMonthFromOptions = getMonthOptions()[getMonthOptions().length - 1].props.value;
                // const getLastMonthFromOptions = getMonthOptions()[0].props.value;
                // setFromMonth(getFirstMonthFromOptions);
                // setToMonth(getLastMonthFromOptions);

            } catch (error) {
                console.error('Failed to fetch warehouses', error);
            }
        };
        fetchWarehouses();
    }, []);

    useEffect(() => {
        if (productData.length > 0) {
            calculatePercentageChanges(productData);
        }
    }, [productData, isMonthToMonth, productsToShow]);

    useEffect(() => {
        if (productData.length > 0) {
            fetchComparisonData();
        }
    }, [numberOfTopRankings]);

    const fetchComparisonData = async () => {
        setLoading(true);
        setDataLoaded(false);

        const productData = [];
        const salesByProductData = {};
        const salesByWarehouseData = {};

        // Extract year and month for the range
        const _fromYear = parseInt(fromMonth.split('-')[0]);
        const _fromMonth = parseInt(fromMonth.split('-')[1]);
        const _toYear = parseInt(toMonth.split('-')[0]);
        const _toMonth = parseInt(toMonth.split('-')[1]);
        const warehousesParam = selectedWarehouses.join(',');

        try {
            // Single call to `/comparison` endpoint
            const response = await axios.get('/comparison', {
                params: {
                    fromYear: _fromYear,
                    fromMonth: _fromMonth,
                    toYear: _toYear,
                    toMonth: _toMonth,
                    city: city,
                    view: view,
                    warehouses: warehousesParam
                }
            });

            const responseData = response.data;

            // Iterate over each month and aggregate data
            Object.keys(responseData).forEach(monthKey => {
                const entries = responseData[monthKey];
                const [year, month] = monthKey.split('-');
                const monthFormatted = `${t(moment(month, 'MM').format('MMMM'))} ${year}`;

                // Aggregate total sales for each month
                const totalSold = entries.reduce((sum, item) =>
                  sum + Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0), 0);

                productData.push({
                    month: monthFormatted,
                    totalSold: totalSold,
                    productQuantities: entries.reduce((acc, item) => {
                        Object.keys(item.productQuantities).forEach(productName => {
                            if (!acc[productName]) {
                                acc[productName] = 0;
                            }
                            acc[productName] += item.productQuantities[productName];
                        });
                        return acc;
                    }, {})
                });

                // Process product quantities by month
                entries.forEach(item => {
                    Object.keys(item.productQuantities).forEach(productName => {
                        if (!salesByProductData[productName]) {
                            salesByProductData[productName] = { name: productName };
                        }
                        if (!salesByProductData[productName][monthFormatted]) {
                            salesByProductData[productName][monthFormatted] = 0;
                        }
                        salesByProductData[productName][monthFormatted] += item.productQuantities[productName];
                    });

                    // Process total sales by warehouse for each month
                    if (!salesByWarehouseData[item.viewName]) {
                        salesByWarehouseData[item.viewName] = { name: item.viewName };
                    }
                    if (!salesByWarehouseData[item.viewName][monthFormatted]) {
                        salesByWarehouseData[item.viewName][monthFormatted] = 0;
                    }
                    salesByWarehouseData[item.viewName][monthFormatted] += Object.values(item.productQuantities).reduce((total, qty) => total + qty, 0);
                });
            });

            // Sort the product data by date for chart display
            productData.sort((a, b) => moment(a.month, 'MMMM YYYY').unix() - moment(b.month, 'MMMM YYYY').unix());
            setProductData(productData);

            setProductsToShow(
              Object.keys(salesByProductData)
                .map((name) => ({
                    name,
                    show: name === name.toUpperCase() // Set `show: true` only if the name is all uppercase
                }))
                .sort((a, b) => {
                    // Sort by uppercase status first, then alphabetically
                    const isAUpper = a.name === a.name.toUpperCase();
                    const isBUpper = b.name === b.name.toUpperCase();

                    if (isAUpper && !isBUpper) return -1; // a comes before b
                    if (!isAUpper && isBUpper) return 1;  // b comes before a
                    return a.name.localeCompare(b.name);  // Alphabetical order within the same group
                })
            );

            calculatePercentageChanges(productData);

            // Prepare data for charts by month
            const monthsList = Array.from(new Set(productData.map(data => data.month)));

            const aggregatedSalesByProductData = monthsList.map((month) => {
                const dataEntry = { month };

                // Sort products by their quantity for the given month, descending
                const sortedProducts = Object.keys(salesByProductData)
                  .sort((a, b) => {
                      const quantityA = salesByProductData[a]?.[month] || 0;
                      const quantityB = salesByProductData[b]?.[month] || 0;
                      return quantityB - quantityA; // Descending order
                  })
                  .slice(0, numberOfTopRankings); // Use dynamic slicing

                sortedProducts.forEach((productName) => {
                    dataEntry[productName] = salesByProductData[productName]?.[month] || 0;
                });

                return dataEntry;
            });

            const aggregatedSalesByWarehouseData = monthsList.map((month) => {
                const dataEntry = { month };
                // Sort warehouse names by quantity for the given month, descending
                const sortedWarehouses = Object.keys(salesByWarehouseData)
                  .filter((key) => key !== 'name')
                  .sort((a, b) => {
                      const quantityA = salesByWarehouseData[a]?.[month] || 0;
                      const quantityB = salesByWarehouseData[b]?.[month] || 0;
                      return quantityB - quantityA; // Descending order
                  })
                  .slice(0, numberOfTopRankings); // Use dynamic slicing

                sortedWarehouses.forEach((viewName) => {
                    dataEntry[viewName] = salesByWarehouseData[viewName]?.[month] || 0;
                });

                return dataEntry;
            });

            setSalesByProductData(aggregatedSalesByProductData);
            setSalesByWarehouseData(aggregatedSalesByWarehouseData);
        } catch (error) {
            console.error("Error fetching comparison data:", error);
        } finally {
            setLoading(false);
            setDataLoaded(true);
        }
    };

    const calculatePercentageChanges = (data) => {
        if (data.length === 0) return; // Exit if there's no data

        // Get the list of product names marked as `show: true`
        const visibleProducts = productsToShow
          .filter(product => product.show)
          .map(product => product.name);

        // Filter `data` to only include quantities for the visible products
        const filteredData = data.map(entry => {
            const filteredTotalSold = visibleProducts.reduce((sum, productName) => {
                return sum + (entry.productQuantities[productName] || 0);
            }, 0);

            return {
                month: entry.month,
                totalSold: filteredTotalSold
            };
        });

        // Calculate percentage changes based on filtered data
        const initialTotal = filteredData[0]?.totalSold || 0;
        const changes = filteredData.map((entry, index) => {
            const currentTotal = entry.totalSold || 0;
            let numericalChange, percentageChange;

            if (index === 0) {
                numericalChange = currentTotal;
                percentageChange = 0;
            } else {
                const baseTotal = isMonthToMonth ? filteredData[index - 1]?.totalSold || 0 : initialTotal;
                numericalChange = currentTotal - baseTotal;
                percentageChange = baseTotal ? ((numericalChange / baseTotal) * 100) : 0;
            }

            return {
                month: entry.month,
                totalSold: currentTotal,
                change: numericalChange,
                percentageChange: percentageChange
            };
        });

        setPercentageChanges(changes);
    };

    const filteredSalesByProductData = salesByProductData.map(monthData => {
        const filteredData = Object.keys(monthData)
          .filter(key =>
            key === 'month' ||
            (!hideIrregularProducts || key === key.toUpperCase())
          )
          // .filter(key =>
          //   key === 'month' ||
          //   (!hideIrregularProducts || key === key.toUpperCase()) // Apply uppercase-only filter if hideIrregularProducts is true
          // )
          .reduce((obj, key) => {
              obj[key] = monthData[key];
              return obj;
          }, {});
        return filteredData;
    });

    const handleViewModeChange = (event) => {
        const isMonthToMonthSelected = event.target.value === 'monthToMonth';
        setIsMonthToMonth(isMonthToMonthSelected);
        calculatePercentageChanges(productData);
    };

    const handleFromMonthChange = (e) => {
        setFromMonth(e.target.value);
    };

    const handleToMonthChange = (e) => {
        setToMonth(e.target.value);
    };

    const handleCityChange = (e) => {
        setCity(e.target.value);
    };

    const handleShowLabelChartProduct = () => {
        setShowLabelChartProduct(!showLabelChartProduct);
    }

    const handleShowQtdChartProduct = () => {
        setShowQtdChartProduct(!showQtdChartProduct);
    }

    const handleShowLabelChartWarehouse = () => {
        setShowLabelChartWarehouse(!showLabelChartWarehouse);
    }

    const handleShowQtdChartWarehouse = () => {
        setShowQtdChartWarehouse(!showQtdChartWarehouse);
    }

    const handleWarehouseChange = (e) => {
        const { value, checked } = e.target;
        setSelectedWarehouses((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((w) => w !== value)
        );
    };

    const handleSelectAllWarehouses = (e) => {
        const { checked } = e.target;
        setAllWarehousesSelected(checked);
        setSelectedWarehouses(checked ? warehouses : []);
    };

    const handleHideIrregularProducts = () => {
        setHideIrregularProducts(!hideIrregularProducts);
    }

    const handleNumberOfTopRankings = (e) => {
        setNumberOfTopRankings(e.target.value);
    }

    const handleProductChange = (e) => {
        const { value, checked } = e.target;

        setProductsToShow((prevProducts) =>
          prevProducts.map((product) => {
              if (product.name === value) {
                  return {
                      ...product,
                      show: checked
                  };
              }
              return product;
          })
        );

        calculatePercentageChanges(productData);
    }

    const handleCheckAllProducts = () => {
        setProductsToShow((prevProducts) =>
          prevProducts.map((product) => ({
              ...product,
              show: true
          }))
        );

        calculatePercentageChanges(productData);
    }

    const handleUncheckAllProducts = () => {
        setProductsToShow((prevProducts) =>
          prevProducts.map((product) => ({
              ...product,
              show: false
          }))
        );

        calculatePercentageChanges(productData);
    }

    const handleResetCache = async () => {
        try {
            await axios.get('/comparison/reset-cache',{});
            setAlert({ open: true, message: t('cacheReseted'), severity: 'success' });
            await fetchComparisonData();
        } catch (error) {
            console.error('Failed to reset cache', error);
        }
    }

    const getMonthOptions = () => {
        const options = [];
        for (let i = 0; i < 12; i++) {
            const date = moment().subtract(i + 1, 'months');
            options.push(
              <MenuItem key={date.format('YYYY-MM')} value={date.format('YYYY-MM')}>
                  {`${t(date.format('MMMM'))} ${date.year()}`}
              </MenuItem>
            );
        }
        return options;
    };

    const getColor = (index) => {
        return colors[index] || getRandomColor();
    };

    const formatChangeColor = (value, theme) => {
        if (value > 0) {
            return theme.palette.success.main; // Dynamic green color for positive values
        }
        if (value < 0) {
            return theme.palette.error.main; // Dynamic red color for negative values
        }
        return theme.palette.text.primary; // Neutral text color for zero
    };

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
      <Container>
          <Typography variant="h4" gutterBottom>
              {t('comparison')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('fromMonth')}</InputLabel>
                      <Select
                        value={fromMonth}
                        onChange={handleFromMonthChange}
                        label={t('fromMonth')}
                        disabled={loading}
                      >
                          {getMonthOptions()}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('toMonth')}</InputLabel>
                      <Select
                        value={toMonth}
                        onChange={handleToMonthChange}
                        label={t('toMonth')}
                        disabled={loading}
                      >
                          {getMonthOptions()}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                  <TextField
                    select
                    label={t('city')}
                    value={city}
                    onChange={handleCityChange}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['Lisbon', 'Porto'].map((cityOption) => (
                        <MenuItem key={cityOption} value={cityOption}>
                            {cityOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('view')}</InputLabel>
                      <Select
                        value={view}
                        onChange={(e) => setView(e.target.value)}
                        label={t('view')}
                        disabled={loading}
                      >
                          <MenuItem value="warehouse">{t('warehouse')}</MenuItem>
                          <MenuItem value="pharmacy">{t('pharmacy')}</MenuItem>
                          <MenuItem value="city">{t('city')}</MenuItem>
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={10} md={10}>
                  <FormControl fullWidth>
                      <InputLabel>{t('warehouse')}</InputLabel>
                      <Select
                        multiple
                        value={selectedWarehouses}
                        onChange={handleWarehouseChange}
                        renderValue={(selected) => selected.join(', ')}
                        disabled={loading}
                      >
                          {warehouses.map((warehouse) => (
                            <MenuItem key={warehouse} value={warehouse}>
                                <Switch
                                  checked={selectedWarehouses.includes(warehouse)}
                                  value={warehouse}
                                  onChange={handleWarehouseChange}
                                />
                                <ListItemText primary={warehouse} />
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                  <FormControlLabel
                    control={
                        <Switch
                          checked={allWarehousesSelected}
                          onChange={handleSelectAllWarehouses}
                        />
                    }
                    label={t('all')}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchComparisonData}
                    disabled={loading || !fromMonth || !toMonth || selectedWarehouses.length === 0}
                    fullWidth
                  >
                      {t('analyze')}
                  </Button>
              </Grid>
          </Grid>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                <CircularProgress />
            </Box>
          ) : (
            dataLoaded && (
              <>
                  {/* New Box for Month-to-Month Percentage Changes */}
                  {percentageChanges.length > 0 && (
                    <div>
                        {/* Other components */}
                        <StatisticsSection
                          t={t}
                          productsToShow={productsToShow}
                          handleViewModeChange={handleViewModeChange}
                          isMonthToMonth={isMonthToMonth}
                          handleProductChange={handleProductChange}
                          handleCheckAllProducts={handleCheckAllProducts}
                          handleUncheckAllProducts={handleUncheckAllProducts}
                          percentageChanges={percentageChanges}
                          formatChangeColor={formatChangeColor}
                        />
                    </div>

                  )}
                  <Box mt={4}>
                      <Typography variant="h5" gutterBottom>
                          {t('salesByProduct')}
                      </Typography>
                      <FormControlLabel
                        control={
                            <Switch
                              checked={showLabelChartProduct}
                              onChange={handleShowLabelChartProduct}
                            />
                        }
                        label={t('showProductName')}
                      />
                      <FormControlLabel
                        control={
                            <Switch
                              checked={showQtdChartProduct}
                              onChange={handleShowQtdChartProduct}
                            />
                        }
                        label={t('showQuantity')}
                      />
                      {/*<FormControlLabel
                        control={
                            <Switch
                              checked={filterLowQuantity}
                              onChange={handleFilterLowQuantity}
                            />
                        }
                        label={t('hideLowQuantity')}
                      />*/}
                      <FormControlLabel
                        control={
                            <Switch
                              checked={hideIrregularProducts}
                              onChange={handleHideIrregularProducts}
                            />
                        }
                        label={t('hideIrregularProducts')}
                      />
                      <div style={{width: '100%', padding: '20px'}}>
                          <ResponsiveBarChart
                            filteredSalesByProductData={filteredSalesByProductData}
                            getColor={getColor}
                            t={(key) => ({
                                sold: 'Sold',
                                totalSold: 'Total Sold',
                            }[key])}
                            showLabelChartProduct={showLabelChartProduct}
                            showQtdChartProduct={showQtdChartProduct}
                            salesByProductData={filteredSalesByProductData}
                          />
                      </div>

                  </Box>
                  <Box mt={4}>
                      <Typography variant="h5" gutterBottom>
                          {t('sales')}
                      </Typography>
                      <FormControlLabel
                        control={
                            <Switch
                              checked={showLabelChartWarehouse}
                              onChange={handleShowLabelChartWarehouse}
                            />
                        }
                        label={t('name')}
                      />
                      <FormControlLabel
                        control={
                            <Switch
                              checked={showQtdChartWarehouse}
                              onChange={handleShowQtdChartWarehouse}
                            />
                        }
                        label={t('showQuantity')}
                        />

                      <FormControlLabel
                        control={
                          <Select value={numberOfTopRankings} onChange={handleNumberOfTopRankings}>
                              {[5, 10, 15, 20].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                              ))}
                          </Select>
                        }
                      />
                      <div style={{width: '100%', padding: '20px'}}>
                          <ResponsiveBarChart
                            filteredSalesByProductData={salesByWarehouseData}
                            getColor={getColor}
                            t={(key) => ({
                                sold: 'Sold',
                                totalSold: 'Total Sold',
                            }[key])}
                            showLabelChartProduct={showLabelChartWarehouse}
                            showQtdChartProduct={showQtdChartWarehouse}
                            salesByProductData={salesByWarehouseData}
                          />
                      </div>
                  </Box>

                  {dataLoaded && (
                    <>
                        <p style={{textAlign: "center", fontSize: "12px"}}>{t('cacheResetMessage')}</p>
                        <Grid container spacing={1} alignItems="center">
                            <Button
                              variant="outlined"
                              color="warning"
                              style={{fontSize: "11px", marginLeft: "auto", marginRight: "auto", display: "block"}}
                              onClick={handleResetCache}>
                                {t('hardReset')}
                            </Button>
                        </Grid>
                    </>
                  )}
                  <Snackbar
                    open={alert.open}
                    autoHideDuration={6000}
                    onClose={() => setAlert({ ...alert, open: false })}
                  >
                      <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                          {alert.message}
                      </Alert>
                  </Snackbar>

              </>
            )
          )}
      </Container>
    );
};

export default Comparison;