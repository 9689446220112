export const colors = [
  'rgba(136, 132, 216, 0.7)',  // purple
  'rgba(130, 202, 157, 0.7)',  // light green
  'rgba(255, 198, 88, 0.7)',   // yellow-orange
  'rgba(208, 237, 87, 0.7)',   // lime green
  'rgba(164, 222, 108, 0.7)',  // light green
  'rgba(216, 136, 136, 0.7)',  // light red
  'rgba(132, 216, 216, 0.7)',  // teal
  'rgba(216, 141, 132, 0.7)',  // coral
  'rgba(132, 216, 141, 0.7)',  // mint
  'rgba(216, 216, 216, 0.7)',  // gray
  'rgba(255, 105, 180, 0.7)',  // pink
  'rgba(124, 252, 0, 0.7)',    // green
  'rgba(135, 206, 250, 0.7)',  // light sky blue
  'rgba(240, 128, 128, 0.7)',  // light coral
  'rgba(255, 165, 0, 0.7)',    // orange
  'rgba(147, 112, 219, 0.7)',  // medium purple
  'rgba(255, 182, 193, 0.7)',  // light pink
  'rgba(173, 216, 230, 0.7)',  // light blue
  'rgba(152, 251, 152, 0.7)',  // pale green
  'rgba(100, 149, 237, 0.7)',  // cornflower blue
];

export const productColors = [
  {
    "INMUNOK": 'rgba(66,121,172,255)'
  },
  {
    "CALMUX": 'rgba(49,181,191,255)'
  },
  {
    "VENOK": 'rgba(241,1,3,255)'
  },
  {
    "ACUOK": 'rgba(48,176,217,255)'
  },
  {
    "URIEX": 'rgba(240,182,1,255)'
  },
  {
    "ARTIOK": 'rgba(214,85,41,255)'
  },
  {
    "ATENOX": 'rgba(158,49,51,255)'
  },
  {
    "LIPOK": 'rgba(58,174,62,255)'
  }
]

export const getProductColor = (productName, index) => {
  // Find the color for the given product name
  const productColorEntry = productColors.find(colorObj => Object.keys(colorObj)[0] === productName);

  if (productColorEntry) {
    // Extract color and make it more transparent
    const [r, g, b] = productColorEntry[productName]
      .match(/\d+/g)
      .map(Number); // Extract RGB values
    return `rgba(${r}, ${g}, ${b}, 0.5)`; // Set alpha to 0.5 for transparency
  }

  // Generate a blue scale color with transparency for unknown products
  const blueBase = 200; // Base blue intensity
  const shade = Math.max(100 - (index * 10), 20); // Reduce brightness for variety
  // return `rgba(${shade}, ${shade}, ${blueBase}, 0.5)`; // Set alpha to 0.5 for transparency
  return '#828aa4'; // Set alpha to 0.5 for transparency
};