import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import axios from '../services/api';
import {useTranslation} from "react-i18next";

const LastActivities = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [activities, setActivities] = useState([]);

  // Fetch Last Activities Data
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get('/audits');
        setActivities(response.data);
      } catch (error) {
        console.error('Failed to fetch last activities:', error);
      }
    };

    fetchActivities();
  }, []);

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(); // e.g., "12/17/2024, 21:13:28"
  };

  if (!activities) {
    return <div></div>
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {t('lastActivities')}
        </Typography>
        <Box sx={{ maxHeight: '300px', overflowY: 'auto' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.background.paper }}>Action</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.background.paper }}>User</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.background.paper }}>Email</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', backgroundColor: theme.palette.background.paper }}>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((activity, index) => (
                  <TableRow key={index}>
                    <TableCell>{t(`audit.${activity.auditEnum}`)}</TableCell>
                    <TableCell>{activity.fullName}</TableCell>
                    <TableCell>{activity.email}</TableCell>
                    <TableCell>{formatDate(activity.createdAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LastActivities;