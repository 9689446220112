import React, {useEffect, useState} from 'react';
import axios from '../services/api';
import {useTranslation} from 'react-i18next';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Snackbar,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import {styled} from '@mui/system';
import 'chart.js/auto';
import EnhancedTable from "./EnhancedTable";
import {useTheme} from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
    margin: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
    },
}));


const Search = () => {
    const { t } = useTranslation();
    const [year, setYear] = useState(localStorage.getItem('year') || '2024');
    const [month, setMonth] = useState(localStorage.getItem('month') || '05');
    const [city, setCity] = useState(localStorage.getItem('city') || 'Lisbon');
    const [view, setView] = useState(localStorage.getItem('view') || 'warehouse');
    const [warehouses, setWarehouses] = useState([]);
    const [selectedWarehouses, setSelectedWarehouses] = useState([]);
    const [allWarehousesSelected, setAllWarehousesSelected] = useState(true);
    const [loading, setLoading] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState('desc');
    const [moreThan, setMoreThan] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [summary, setSummary] = useState([]);
    const [diff, setDiff] = useState([]);
    const [comparisonMode, setComparisonMode] = useState(true);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'success' });
    const theme = useTheme();

    useEffect(() => {
        const fetchWarehouses = async () => {
            try {
                const response = await axios.get('/storage/warehouses');
                setWarehouses(response.data);

                // Select all warehouses by default
                setSelectedWarehouses(response.data);
            } catch (error) {
                console.error('Failed to fetch warehouses', error);
            }
        };
        fetchWarehouses();
    }, []);

    useEffect(() => {
        localStorage.setItem('year', year);
        localStorage.setItem('month', month);
        localStorage.setItem('city', city);
        localStorage.setItem('view', view);
    }, [year, month, city, view]);

    const fetchSummary = async () => {
        setLoading(true);
        setMoreThan('');
        setNameFilter('');
        try {
            const warehousesParam = selectedWarehouses.join(','); // Convert array to comma-separated string
            const response = await axios.get('/comparison/simple', {
                params: {
                    year,
                    month,
                    city,
                    view,
                    warehouses: warehousesParam, // Send warehouses as a comma-separated string
                }
            });
            setSummary(response.data['current']);
            setDiff(response.data['diff']);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleResetCache = async (isPeriod) => {
        try {
            const warehousesParam = selectedWarehouses.join(','); // Convert array to comma-separated string
            await axios.get('/comparison/reset-cache',isPeriod ? {
                params: {
                    year,
                    month,
                    warehouses: warehousesParam, // Send warehouses as a comma-separated string
                }
            } : {});
            setAlert({ open: true, message: t('cacheReseted'), severity: 'success' });
            fetchSummary();
        } catch (error) {
            setAlert({ open: true, message: error.response.data.message, severity: 'error' });
        }
    }

    const getProductNames = (summary) => {
        const productNames = new Set();
        summary.forEach((item) => {
            Object.keys(item.productQuantities).forEach((product) => {
                productNames.add(product);
            });
        });
        return Array.from(productNames).sort();
    };

    const handleSort = (column) => {
        const isAsc = orderBy === column && orderDirection === 'asc';
        setOrderDirection(isAsc ? 'desc' : 'asc');
        setOrderBy(column);

        const sortedSummary = [...summary].sort((a, b) => {
            const aValue =
              column === 'total'
                ? a.totalSales
                : a.productQuantities[column] || 0;
            const bValue =
              column === 'total'
                ? b.totalSales
                : b.productQuantities[column] || 0;

            return isAsc ? aValue - bValue : bValue - aValue;
        });
        setSummary(sortedSummary);
    };

    const handleWarehouseChange = (e) => {
        const { value, checked } = e.target;
        setSelectedWarehouses((prevSelected) =>
          checked ? [...prevSelected, value] : prevSelected.filter((w) => w !== value)
        );
    };

    const handleSelectAllWarehouses = (e) => {
        const { checked } = e.target;
        setAllWarehousesSelected(checked);
        setSelectedWarehouses(checked ? warehouses : []);
    };

    const handleCompareMode = (e) => {
        setComparisonMode(e.target.checked);
    };

    const productNames = getProductNames(summary.concat(diff));

    const renderCell = (currentValue, diffValue) => {

        if (!comparisonMode) {
            // Only display the current value when comparisonMode is disabled
            return <Typography>{currentValue || 0}</Typography>;
        }

        if (diffValue === undefined) {
            // If no difference value, display only the current value
            return <Typography>{currentValue || 0}</Typography>;
        }

        // Determine color for the difference value
        let color = theme.palette.text.primary; // Default to primary text color
        let diffDisplay = diffValue;

        if (diffValue > 0) {
            color = theme.palette.success.main; // Green for positive difference
            diffDisplay = `+${diffValue}`;
        } else if (diffValue < 0) {
            color = theme.palette.error.main; // Red for negative difference
        }

        // Render the current value and difference with styling and improved communication
        return (
          <Typography component="span" variant="body2">
              <strong>{currentValue || 0}</strong>{' '}
              <Typography
                component="span"
                variant="body2"
                sx={{
                    color,
                    fontWeight: 'bold',
                }}
              >
                  {diffValue === 0 ? '' : `(${diffDisplay})`}
              </Typography>
          </Typography>
        );
    };

    const getFilteredSummary = () => {
        return summary.filter((item) => {
            const matchesNameFilter = !nameFilter || item.viewName.toLowerCase().includes(nameFilter.toLowerCase());
            const matchesMoreThanFilter = !moreThan || item.totalSales >= Number(moreThan);
            return matchesNameFilter && matchesMoreThanFilter;
        });
    };

    const calculateTotalRow = (filteredData) => {
        const totalSales = filteredData.reduce((sum, item) => sum + item.totalSales, 0);
        const totalProducts = productNames.map((productName) =>
          filteredData.reduce((sum, item) => sum + (item.productQuantities[productName] || 0), 0)
        );
        const totalDiff = productNames.map((productName) =>
          diff.reduce((sum, item) => {
              const diffItem = diff.find((d) => d.viewName === item.viewName) || {};
              return sum + (diffItem.productQuantities?.[productName] || 0);
          }, 0)
        );
        return { totalSales, totalProducts, totalDiff };
    };

    const filteredSummary = getFilteredSummary();
    const totalRow = calculateTotalRow(filteredSummary);

    const handleMoreThanChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Only accept numbers
            setMoreThan(value);
        }
    };

    const handleNameFilterChange = (e) => {
        setNameFilter(e.target.value);
    };

    return (
      <Container>
          <Typography variant="h4" gutterBottom>
              {t('salesSummary')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('year')}
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {[2022, 2023, 2024, 2025].map((yearOption) => (
                        <MenuItem key={yearOption} value={yearOption}>
                            {yearOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('month')}
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map(
                        (monthOption) => (
                          <MenuItem key={monthOption} value={monthOption}>
                              {monthOption}
                          </MenuItem>
                        )
                      )}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    select
                    label={t('city')}
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="outlined"
                    fullWidth
                    disabled={loading}
                  >
                      {['Lisbon', 'Porto'].map((cityOption) => (
                        <MenuItem key={cityOption} value={cityOption}>
                            {cityOption}
                        </MenuItem>
                      ))}
                  </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth variant="outlined">
                      <InputLabel>{t('view')}</InputLabel>
                      <Select
                        value={view}
                        onChange={(e) => setView(e.target.value)}
                        label={t('view')}
                        disabled={loading}
                      >
                          <MenuItem value="warehouse">{t('warehouse')}</MenuItem>
                          <MenuItem value="pharmacy">{t('pharmacy')}</MenuItem>
                          <MenuItem value="city">{t('city')}</MenuItem>
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={8} sm={6} md={3}>
                  <FormControl fullWidth>
                      <InputLabel>{t('warehouse')}</InputLabel>
                      <Select
                        multiple
                        value={selectedWarehouses}
                        onChange={handleWarehouseChange}
                        renderValue={(selected) => selected.join(', ')}
                        disabled={loading}
                      >
                          {warehouses.map((warehouse) => (
                            <MenuItem key={warehouse} value={warehouse}>
                                <Switch
                                  checked={selectedWarehouses.includes(warehouse)}
                                  value={warehouse} // Set the value to the warehouse name
                                  onChange={handleWarehouseChange}
                                />
                                <ListItemText primary={warehouse} />
                            </MenuItem>
                          ))}
                      </Select>
                  </FormControl>
              </Grid>
              <Grid item xs={3} sm={4} md={3}>
                  <FormControlLabel
                    control={
                        <Switch
                          checked={allWarehousesSelected}
                          onChange={handleSelectAllWarehouses}
                        />
                    }
                    label={t('selectAllWarehouses')}
                  />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={fetchSummary}
                    disabled={loading}
                    fullWidth
                  >
                      {t('analyze')}
                  </Button>
              </Grid>


              {summary.length > 0 && (
                <>
                    <Grid item xs={4} sm={4} md={4}>
                        <TextField
                          //label={t('nameOf')}
                          value={nameFilter}
                          onChange={handleNameFilterChange}
                          variant="outlined"
                          fullWidth
                          disabled={loading || summary.length === 0}
                          placeholder={t('filterByName')}
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <TextField
                          //label={t('moreThan')}
                          value={moreThan}
                          onChange={handleMoreThanChange}
                          variant="outlined"
                          fullWidth
                          disabled={loading || summary.length === 0}
                          placeholder={t('sales')}
                          inputMode="numeric"
                          type="tel"
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormControlLabel
                          control={
                              <Switch
                                checked={comparisonMode}
                                onChange={handleCompareMode}
                              />
                          }
                          label={t('comparePrevMonth')}
                        />
                    </Grid>
                </>
              )}
          </Grid>

          {loading && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                <CircularProgress />
            </Box>
          )}

          {summary.length === 0 ? (
            <></>
          ): (
            <>
                <div style={{marginTop: 10}}></div>
                <EnhancedTable
                  view={view}
                  productNames={productNames}
                  handleSort={handleSort}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  filteredSummary={filteredSummary}
                  diff={diff}
                  t={t}
                  renderCell={renderCell}
                  totalRow={totalRow}
                />

                {(summary.length > 0 && !loading) && (
                  <>
                      <p style={{textAlign: "center", fontSize: "12px"}}>{t('cacheResetMessage')}</p>
                      <Grid container spacing={1} alignItems="center">
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{fontSize: "11px", marginLeft: "auto", marginRight: "auto", display: "block"}}
                            onClick={() => handleResetCache(true)}>
                              {t('resetSelectedPeriod')} - {month}/{year}
                          </Button>
                          <Button
                            variant="outlined"
                            color="warning"
                            style={{fontSize: "11px", marginLeft: "auto", marginRight: "auto", display: "block"}}
                            onClick={() => handleResetCache(false)}>
                              {t('hardReset')}
                          </Button>
                      </Grid>
                  </>
                )}
                <Snackbar
                  open={alert.open}
                  autoHideDuration={6000}
                  onClose={() => setAlert({ ...alert, open: false })}
                >
                    <Alert onClose={() => setAlert({ ...alert, open: false })} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            </>
          )}
      </Container>
    );
};

export default Search;